import React, { useState } from 'react'
import CIcon from '@coreui/icons-react'
import CIconButton from '../buttons/CIconButton'
import CardErrors from '../text/CAlertErrors'
import { dispatchResizeEvent } from '../../events/eventDispatcher'

import { CLink, CCardHeader, CCol, CRow, CCard, CCardBody, CCollapse } from '@coreui/react'
import { PCardComponentProps } from '../../models/Types'
import { DEBUG, FD } from '../../fela/api/fela.constants'

const PCollapseCard = (props: PCardComponentProps) => {
  const { header, card, button } = props
  const { title, link } = header

  console.log('PCollapse card props are: ', props)

  const [expanded, setExpanded] = useState(false)
  const handleExpansion = () => {
    setExpanded(!expanded)
    dispatchResizeEvent(50)
  }

  return (
    <div className={card?.classes?.wrapper}>
      <CCard className={card?.classes?.container}>
        <CCardHeader style={{ cursor: 'pointer' }} onClick={handleExpansion} className={header?.classes?.container}>
          <div className={header?.classes?.content}>
            <span className={title?.classes?.content}> {title?.content}</span>

            <CLink className={link?.classes?.container}>
              <CIcon name={expanded ? 'cil-chevron-top' : 'cil-chevron-bottom'} />
            </CLink>
          </div>

          {button?.display && (
            <div className={button?.classes?.container}>
              <CIconButton className={button?.classes?.content} {...button.attributes} />
            </div>
          )}
        </CCardHeader>
        <CCollapse className={card?.classes?.dynamic} show={expanded}>
          <div className={card?.classes?.container}>{props.children}</div>
        </CCollapse>
      </CCard>
    </div>
  )
}
export default PCollapseCard
// <CRow className={header?.classes?.container}>
//   <CCol {...breakpointColumns}></CCol>
//         </CCol>
// </CRow>
// <CardErrors cardErrors={card?.errors} />
