//@ts-ignore
import cloneDeep from 'lodash/cloneDeep'
import { MODAL_ACTION } from '../modal/Types'
import { defaultModalState } from '../modal/Constants'
import { PLFIELD, PayloadPaths } from './StateTypes'
import { objectHasField, getValueFromFieldPath, getObjectFromFieldPath } from '../base/common'

export const modalStateReducer = (modalState: any, modalAction: any) => {
  //console.log('678.0-]]]] pathspot-react: api: states: StateModal: modalStateReducer: [modalAction, modalState', [modalAction, modalState])
  let newModalState = { ...modalState }
  switch (modalAction.type) {
    case MODAL_ACTION.SHOW:
      newModalState.type = objectHasField(modalAction, [...PayloadPaths.modalType]) ? getValueFromFieldPath(modalAction, [...PayloadPaths.modalType]) : undefined
      newModalState.display = true
      newModalState.handles = objectHasField(modalAction, [...PayloadPaths.handles]) ? getValueFromFieldPath(modalAction, [...PayloadPaths.handles]) : undefined
      newModalState.dispatches = objectHasField(modalAction, [...PayloadPaths.dispatches]) ? getValueFromFieldPath(modalAction, [...PayloadPaths.dispatches]) : undefined
      newModalState.kwargs = objectHasField(modalAction, [...PayloadPaths.kwargs]) ? getValueFromFieldPath(modalAction, [...PayloadPaths.kwargs]) : undefined
      //console.log('11.0-]]]] New modal state is: ', newModalState)
      return { ...newModalState }
    case MODAL_ACTION.HIDE:
      newModalState.display = false
      return { ...newModalState }
    case MODAL_ACTION.CLEAR:
      newModalState = cloneDeep(defaultModalState)
      return { ...newModalState }
    default:
      return { ...modalState }
  }
}
