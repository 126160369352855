import React from 'react'
import { useFela } from 'react-fela'
import { container, debugBorder } from '../../styles/containers'
import { v4 as uuidv4 } from 'uuid'
import { buttonText, itemText, pageTitle } from '../../styles/text'
import PElement from '../containers/PElement'
const componentUUID = uuidv4()
const PItemText = (props: any) => {
  // console.log('////////////////////////Pitem text props are: ', props)
  const {
    displayText: _displayText,
    value,
    alignItems,
    justifyContent,
    width,
    fontSize,
    color,
    backgroundColor,
    fontWeight,
    fontStyle,
    isRow,
    margin,
    padding,
    border,
    textAlign,
    elementKey,
  } = props

  const flexDirection = isRow ? 'row' : 'column'
  const { css } = useFela()
  const displayText = _displayText || value
  const key = `${displayText}-${componentUUID}`

  return (
    <PElement
      showComment
      isRow={isRow}
      margin={margin}
      width={width}
      justifyContent={justifyContent}
      alignItems={alignItems}
      flexWrap={'wrap'}
    >
      <span
        key={key}
        className={css(
          itemText({ fontSize, color, backgroundColor, fontWeight, flexDirection, margin, padding, border, textAlign, fontStyle })
        )}
      >{`${displayText}`}</span>
    </PElement>
  )
}

export default PItemText
