import * as React from 'react'
import {
  pathspotPrimary,
  bananaYellow,
  pathspotGray,
  pathspotRed,
  numericOrange,
  pathspotWhite,
  pathspotSecondary,
  pathspotWhite50p,
} from '../../../../ts/colors'

const PathSpotFilterOffRaw = (props: any) => {
  const { fillColor, strokeColor, checked, className, viewBox, width, height } = props
  return (
    <svg viewBox={viewBox || '0 0 512 512'} className={className || 'p-icon-auto'}>
      <path
        fill={fillColor || pathspotPrimary}
        d="M40 16v37.828L109.024 136h41.791L76.896 48H459.51L304 242.388v158.985L241.373 464H240v-96h-32v128h46.627L336 414.627V253.612l160-200V16H40z"
      />
      <path
        fill={fillColor || pathspotPrimary}
        d="M166.403 248.225l60.461-60.462-22.627-22.628-60.462 60.462-60.462-60.462-22.626 22.628 60.461 60.462-60.461 60.462 22.626 22.627 60.462-60.462 60.462 60.462 22.627-22.627-60.461-60.462z"
      />
    </svg>
  )
}

const PathSpotFilterOff = React.memo(PathSpotFilterOffRaw)
export default PathSpotFilterOff
