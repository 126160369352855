export type valueof<T> = T[keyof T] //give the value of an object/enum that is type T where the accessor argument must be a key within T
export type PropertyFunction<T> = () => T ///just shorthand for speciying a type that is a function with no arguments and returns some type i.e. ()=> void is the same as PropertyFunction<void>
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

export type ReduxArgs = {
  type: string
  [key: string]: any
}
export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

type ActionType = {
  create: PropertyFunction<void> //Doing it this way makes it non obvious to the linter that it is a function
  replace: () => void
  update: () => void
  delete: () => void
}
//using the value in an enum, generate a new type defining and object with property defined as another type [enumValue]: T
export type PropertyFromEnum<E extends PropertyKey, T> = { [Property in E]: T }
// export type PropertyFromEnumMember<E, [M in keyof E], T> = { [M in keyof E]: T }

type Action = ActionMap<ActionType>[keyof ActionMap<ActionType>] //this gives:
// Action is {type: 'create', payload: PropertyFunction<void> | {type: 'replace', payload: ()=>{}} | ...etc}
///Type Shenanigans

///Enum Shenanigans

enum TryMe {
  getout = 'pilliferous',
  dontnobody = 'dontnobody',
  youreaprop = 'youreaprop',
}
type TestMeDoh = {
  firstname: string
  lastname: string
}
type ObjectEnum = PropertyFromEnum<TryMe, TestMeDoh>
type Other = {
  [Property in TryMe]: TestMeDoh
}

type EnumThing = {
  [PropertyType in TryMe as symbol]: TestMeDoh
}
type EnumSprang = {
  [Key in TryMe]: TestMeDoh
}
type EnumSwang = {
  [PropertyKey in keyof TryMe]: TestMeDoh
}
///Typechecking

///Basically attempting to check 'isEnumMember' or isTypeMember or isObjectMember
// export const isGoalsAction = (obj: any): obj is GOALS_ACTION => {
//   return Object.values(GOALS_ACTION).some((eVal: GOALS_ACTION) => eVal === (obj as GOALS_ACTION))
// }
// export const isModalAction = (obj: any): obj is MODAL_ACTION => {
//   return Object.values(MODAL_ACTION).some((eVal: MODAL_ACTION) => eVal === (obj as MODAL_ACTION))
// }

//component definitions:
// const Code: React.FC = ({ children }) => {} export default Code;
// export type FilterEntityTypes = FilterType extends Omit<FilterType, FilterType.dateRange>

// export type Filter<F in keyof FilterType> extends F===FilterType.dateRange ? FilterDateRange : FilterEntentity =  {
//   newAffils: boolean
// }

// export interface FilterContingents {
//   [T in keyof EntityFilterType]: Array<Number>
// }
// }
// enum FilterElementType {
//   []
// }

/// Typescript you are the worst...........
const TSelectors = {
  Selector1: 'selector1',
  Selector2: 'selector2',
}
type TSelectorType = typeof TSelectors
type TSelectorKeyType = keyof TSelectorType
export type TMapType = {
  [Key in TSelectorKeyType]?: { [key in TSelectorKeyType]: TSelectorKeyType } | { [key in TSelectorKeyType]: TSelectorType[key] }
} // Why this must be done this way is beyond frustrating

// Remove the 'kind' property
type RemoveKindField<Type> = {
  [Property in keyof Type as Exclude<Property, 'kind'>]: Type[Property]
}

interface Circle {
  kind: 'circle'
  radius: number
}

type KindlessCircle = RemoveKindField<Circle>

const KindlessCircleOBJ: KindlessCircle = {
  radius: 6,
}
