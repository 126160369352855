import React from 'react'
import pick from 'lodash/pick'
import cloneDeep from 'lodash/cloneDeep'
import { ENV } from '../../constants/generic'
import { Buffer } from 'buffer'
import { unzipSync } from 'browserify-zlib'

export const getRefDimensions = (anyRef: any) => {
  if (anyRef?.current !== null) {
    // console.log('Ref.current.offsetwidth: ', ref?.current?.getBoundingClientRect())
    const domRect = anyRef?.current?.getBoundingClientRect()

    return domRect
  } else {
    return null
  }
}

export const objectIncludes = (object: any, key: any) => Object.keys(object).includes(key)

export const removeProperty = (key: string, { [key]: value, ...restObject }) => restObject

export const deepCloneProperty = (key: string, obj: any) => {
  return pick(obj, key)
}
export const mergeObjects = (source: any, target: any) => {
  return Object.assign(target, source)
}
export const emptyField = (attr: any) => {
  switch (typeof attr) {
    case 'object':
      if (attr === null) return true
      if (attr.length === 0) return true
      if (Object.keys(attr).length === 0) return true
      return false
    case 'string':
      if (attr === '') return true
      return false
    case 'number':
      if (isNaN(attr)) return true
      return false
    case 'undefined':
      return true
    case 'boolean':
      return false
    default:
      return false
  }
}
export const allFieldsEmpty = (object: any) => {
  return Object.keys(object).every((key: any) => emptyField(object[key]))
}

export const mapValueFromKeyMatch = (targetObject: any, sourceObject: any) => {
  return Object.fromEntries(
    Object.entries(sourceObject).map(([key, value]: any) => {
      // console.log('Key is: ', key)
      let targetkey = Object.keys(targetObject).find((newkey: any) => key.toLowerCase().search(newkey.toLowerCase()) >= 0)
      // console.log('Target key is: ', targetkey)
      return targetkey && typeof targetkey === 'string' ? [[targetkey], value] : [[key], value]
    })
  )
}

export const replaceObjectFieldsKVP = (targetObject: any, keyValuePairs: any) => {
  return Object.fromEntries(
    Object.entries(targetObject).map(([key, value]: any) => {
      const keyIdx = keyValuePairs.keys.indexOf(key)
      return keyIdx >= 0 ? [key, keyValuePairs.values[keyIdx]] : [key, value]
    })
  )
}

export const replaceEmptyObjectFields = (sourceObject: any, replacementValue: any) => {
  return Object.fromEntries(
    Object.entries(sourceObject).map(([key, value]: any) => {
      const testObject = { [key]: value }
      // console.log('Test object is:', testObject);

      let returnVal: any = ''
      // TODO (nathan): should refactor this to make sure it cant effect anything else
      if (key == 'status') {
        returnVal = [key, statusMapping(value)]
      } else if (key == 'submissionDateTime' && value && (!sourceObject['status'] || sourceObject['status'] == 'Not Started')) {
        returnVal = ['submissionDateTime', replacementValue]
      } else if (key == 'firstScan' || key == 'lastScan') {
        returnVal = !sourceObject['employeeId'] ? [key, 'No data - Anonymous mode'] : [key, value ? value : replacementValue]
      } else {
        returnVal = emptyField(value) ? [key, replacementValue] : [key, value]
      }
      return returnVal
    })
  )
}

export const statusMapping = (stat: string) => {
  switch (stat) {
    case 'PASS':
      return 'Success'
    case 'FAIL':
      return 'Fail'
    case 'QUIT':
    case 'TIMEOUT':
      return 'Incomplete'
    default:
      return 'Not Started'
  }
}

export const statusClassNameMapping = (item: any) => {
  /* Note: className= text-* from coreui wasn't being consistent 
    so assigned color directly using defined variables in wellness_checks.scss
  */
  switch (item.displayStatus) {
    case 'Success':
      return { color: 'rgb(24, 200, 53)' }
    case 'Fail':
      return { color: 'rgb(202, 44, 39)' }
    case 'Incomplete':
      return { color: 'rgb(237, 142, 14)' }
    default:
      return { color: 'rgb(167, 174, 174)' }
  }
}

/**
 * for table in wellness checks
 * should be greyed out and disabled if
 * status == "not started"
 */
export const isDisabledButton = (item: any) => {
  // console.log('disabled button: ', item.displayStatus, item.status);
  if (item.displayStatus == 'Not Started' || item.status == 'QUIT') {
    return true
  }
  return false
}

export const styledDisabledButton = (item: any) => {
  if (item.displayStatus == 'Not Started' || item.status == 'QUIT') {
    return { color: 'grey', borderColor: 'grey', MarginTop: '10px' }
  }
  return { marginTop: '10px' }
}

export const objectHasField = (targetObject: any, fieldPathList: any): boolean => {
  //console.log("77.1-]]]] ObjectHasField? targetObject: ", cloneDeep(targetObject))
  //console.log("77.2-]]]] ObjectHasField? fieldPathList: ", cloneDeep(fieldPathList))
  if (targetObject) {
    if (fieldPathList && fieldPathList.length > 1) {
      const newTargetObject = targetObject[fieldPathList.shift()]
      return objectHasField(newTargetObject, fieldPathList)
    } else if (fieldPathList.length === 1) {
      let objectDefined = targetObject[fieldPathList.shift()]
      return objectDefined === undefined ? false : true
    } else {
      //should never get here, means list length is 0
      return false
    }
  } else {
    return false
  }
}

export const getValueFromFieldPath = (targetObject: any, fieldPathList: any): any => {
  if (targetObject) {
    if (fieldPathList && fieldPathList.length > 1) {
      const newTargetObject = targetObject[fieldPathList.shift()]
      return getValueFromFieldPath(newTargetObject, fieldPathList)
    } else if (fieldPathList.length === 1) {
      let objectDefined = targetObject[fieldPathList.shift()]
      return objectDefined
    } else {
      //should never get here, means list length is 0
      return undefined
    }
  } else {
    return undefined
  }
}
export const getObjectFromFieldPath = (targetObject: any, fieldPathList: any): any => {
  if (fieldPathList.length > 1) {
    const newTargetObject = targetObject[fieldPathList.shift()]
    //console.log("88.0-]]]] Path list is > 1, New target object is: ", cloneDeep(newTargetObject))
    return getObjectFromFieldPath(newTargetObject, fieldPathList)
  } else if (fieldPathList.length === 1) {
    //console.log("88.1-]]]] Path list === 1, target object is: ", cloneDeep(targetObject))
    //console.log("88.2-]]]] Path list === 1, fieldPathList is: ", cloneDeep(fieldPathList))
    return {
      returnObject: targetObject,
      field: fieldPathList.shift(),
    }
  } else {
    //console.log("88.3-]]]] Path list === 0, target object is: ", cloneDeep(targetObject))
    return {
      returnObject: targetObject,
      field: undefined,
    }
  }
}
export const strList2CommaSpaceStr = (items: any) => {
  return `${items
    .map((item: any) => {
      return item && item.label ? `${item.label}` : `${item}`
    })
    .join(', ')}`
}

export const unzipb64Payload = (payload: string) => {
  //console.log('Payload recieved is: ', payload)
  try {
    if (payload === undefined) {
      //console.log('Unzip called with no payload to unzip.');
      return undefined
    }
    const decodedPayload = Buffer.from(payload, 'base64')
    //console.log('0.0.2-]]]] unzipb64Payload: decodedPayload: ', decodedPayload);
    //const restoredBuffer = zlib.unzipSync(decodedPayload, zlibCallback);
    // console.log('zlib is: ', zlib);
    const restoredBuffer = unzipSync(decodedPayload)

    //console.log('0.0.3-]]]] unzipb64Payload: restoredBuffer: ', restoredBuffer);
    const restoredJsonString = Buffer.from(restoredBuffer).toString('utf-8')
    //console.log('0.0.4-]]]] unzipb64Payload:restoredJsonString is: ', restoredJsonString);
    const restoredJson = JSON.parse(restoredJsonString)
    // console.log('0.0.5-]]]] unzipb64Payload: restoredJson is: ', restoredJson);
    return restoredJson
  } catch (e) {
    const err: any = e
    if (process.env.NODE_ENV === ENV.DEV) console.log('Error unzipping payload...', [err.toString(), Buffer.from(payload, 'base64')])
    return undefined
  }
}

export const b64decode = (data: any) => {
  const decodedPayload = Buffer.from(data, 'base64')
  console.log('B64 decoded string is: ', decodedPayload)
  //console.log('0.0.2-]]]] unzipb64Payload: decodedPayload: ', decodedPayload);
  //const restoredBuffer = zlib.unzipSync(decodedPayload, zlibCallback);
  // console.log('zlib is: ', zlib);
  // const restoredBuffer = unzipSync(decodedPayload)
  const restoredBuffer = unzipSync(decodedPayload)

  //console.log('0.0.3-]]]] unzipb64Payload: restoredBuffer: ', restoredBuffer);
  const restoredString = Buffer.from(restoredBuffer).toString('utf-8')
  return restoredString
}
export const pkLabelHyphenatedString = (pk: any, label: string, nullBack: string = '-', unkBack: string = '-') => {
  const returnStr = `${pk} - ${label}`
  if (returnStr === 'null - null') {
    return nullBack
  } else if (returnStr === 'undefined - undefined') {
    return unkBack
  } else {
    return returnStr
  }
}

export const mergeRefs = (...refs: any) => {
  return (node: any) => {
    for (const ref of refs) {
      ref.current = node
    }
  }
}
export const parseParamString = (paramString: string) => {
  switch (paramString) {
    case 'false':
      return false
    case 'true':
      return true
    case 'null':
      return null
    default:
      return parseInt(paramString)
  }
}
export const parseParam = (param: any, searchString: any) => {
  const paramSplit = searchString.split(param)
  if (paramSplit && paramSplit.length > 1) {
    const terminatedValue = paramSplit[1].split('=')
    if (terminatedValue && terminatedValue.length > 1) {
      const paramValue = terminatedValue[1].split('&')
      if (paramValue && paramValue.length > 0) {
        // console.log('Param and value is: ', [param, paramValue[0]]);
        return paramValue[0]
      }
    } else {
      // console.log('Param has no value:', [param, terminatedValue]);
    }
  } else {
    // console.log('Param not found: ', [param, paramSplit]);
  }
  return undefined
}

//= Object.keys(errors).some((key: string) => errors[key] && errors[key].length > ˆ∆)
// Object.keys(eventDetails).forEach((key: string) => errors[key] = []

// export const cloneProperty = (key: string, { [key]: value, ...restObject }) => { return { [key]: value } };

// const clonePropertyA = (targetKey: string, restObject: any) => {
//     let newObject = { ...restObject }
//     return newObject[targetKey]
// }

// return Object.fromEntries(Object.entries(restObject).map(([key, value]: any) => {
//     if (key === targetKey) {
//         return [key, value]
//     }
// }
// )
// )

// const win = window.open(`#/sensors/${item.sensorId}`, '_blank')
// if (win !== null) win.focus()
