import * as React from 'react'

const PathSpotInfoRaw = (props: any) => {
  const { fillColor, strokeColor, checked, className, viewBox, width, height } = props
  return (
    <svg viewBox={viewBox || '0 0 500 500'} className={className || 'p-icon-5xl'}>
      <path
        d="M250 478c-60.901 0-118.157-23.716-161.22-66.779S22 310.901 22 250 45.716 131.843 88.78 88.78 189.099 22 250 22s118.157 23.716 161.221 66.78S478 189.099 478 250s-23.716 118.157-66.779 161.221S310.902 478 250 478z"
        fill={fillColor || '#bcbec0'}
      />
      <path
        d="M250 44c55.025 0 106.756 21.428 145.664 60.336C434.572 143.244 456 194.975 456 250s-21.428 106.756-60.336 145.664S305.025 456 250 456s-106.756-21.428-145.664-60.336C65.428 356.756 44 305.025 44 250s21.428-106.756 60.336-145.664C143.244 65.428 194.975 44 250 44m0-44C111.929 0 0 111.929 0 250s111.929 250 250 250 250-111.929 250-250S388.071 0 250 0z"
        fill={strokeColor || '#231f20'}
      />
      <path
        d="M291.979 197.527l-43.463 150.742c-2.406 8.622-3.604 14.279-3.604 16.961 0 1.558.636 3.004 1.908 4.346 1.272 1.346 2.615 2.014 4.028 2.014 2.403 0 4.803-1.06 7.208-3.18 6.36-5.227 13.993-14.7 22.898-28.41l7.208 4.24C266.819 381.416 244.134 400 220.105 400c-9.19 0-16.505-2.58-21.943-7.739-5.442-5.159-8.163-11.693-8.163-19.611 0-5.227 1.198-11.873 3.604-19.929l29.47-101.343c2.827-9.753 4.24-17.1 4.24-22.049 0-3.107-1.346-5.863-4.028-8.269-2.686-2.403-6.36-3.604-11.025-3.604-2.12 0-4.664.074-7.633.212l2.756-8.481 71.873-11.661h12.721zM278.834 100c8.763 0 16.145 3.042 22.155 9.117 6.007 6.078 9.011 13.431 9.011 22.049s-3.042 15.975-9.117 22.049c-6.078 6.078-13.431 9.117-22.049 9.117-8.481 0-15.763-3.039-21.837-9.117-6.078-6.075-9.117-13.428-9.117-22.049s3.001-15.972 9.011-22.049c6.007-6.075 13.322-9.117 21.943-9.117z"
        fill={strokeColor || '#231f20'}
      />
    </svg>
  )
}

const PathSpotInfo = React.memo(PathSpotInfoRaw)
export default PathSpotInfo
