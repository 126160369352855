export const lineHeightLg = '.5 !default'
export const lineHeightSm = '1.5 !default'

export const spacer = '1rem !default'
export const borderWidth = '1px !default'
export const fontSizeBase = '.875rem !default'
export const fontSizeLg = `${fontSizeBase} * 1.25 !default`
export const fontSizeSm = `${fontSizeBase} * .875 !default`

export const fontWeightLighter = 'lighter !default'
export const fontWeightLight = '300 !default'
export const fontWeightNormal = '400 !default'
export const fontWeightBold = '700 !default'
export const fontWeightBolder = 'bolder !default'

export const fontWeightBase = `${fontWeightNormal} !default`
export const lineHeightBase = '1.5 !default'

export const h1FontSize = `${fontSizeBase} * 2.5 !default`
export const h2FontSize = `${fontSizeBase} * 2 !default`
export const h3FontSize = `${fontSizeBase} * 1.75 !default`
export const h4FontSize = `${fontSizeBase} * 1.5 !default`
export const h5FontSize = `${fontSizeBase} * 1.25 !default`
export const h6FontSize = `${fontSizeBase} !default`

export const headingsMarginBottom = `${spacer} / 2 !default`
export const headingsFontFamily = 'null !default'
export const headingsFontWeight = '500 !default'
export const headingsLineHeight = '1.2 !default'
export const headingsColor = 'null !default'

export const display1Size = '6rem !default'
export const display2Size = '5.5rem !default'
export const display3Size = '4.5rem !default'
export const display4Size = '3.5rem !default'

export const display1Weight = '300 !default'
export const display2Weight = '300 !default'
export const display3Weight = '300 !default'
export const display4Weight = '300 !default'
export const displayLineHeight = `${headingsLineHeight} !default`

export const leadFontSize = `${fontSizeBase} * 1.25 !default`
export const leadFontWeight = '300 !default'

export const smallFontSize = '80% !default'

export const textMuted = 'gray700 !default'

export const blockquoteSmallColor = 'gray600 !default'
export const blockquoteSmallFontSize = `${smallFontSize} !default`
export const blockquoteFontSize = `${fontSizeBase} * 1.25 !default`

export const hrBorderColor = 'rgba(black, .2) !default'
export const hrBorderWidth = `${borderWidth} !default`

export const vrBg = 'rgba(black, .2) !default'
export const vrWidth = `${borderWidth} !default`

export const markPadding = '.2em !default'

export const dtFontWeight = `${fontWeightBold} !default`

export const kbdBoxShadow = 'inset 0 .1rem 0 rgba(black, .25) !default'
export const nestedKbdFontWeight = `${fontWeightBold} !default`

export const listInlinePadding = '.5rem !default'

export const markBg = '#fcf8e3 !default'

export const hrMarginY = `${spacer} !default`
