import React, { useLayoutEffect, useRef } from 'react'
import { useFela } from 'react-fela'
import { content } from '../../styles/containers'
import { v4 as uuidv4 } from 'uuid'
import { PComponent } from '../../types/fela.types'
const key = `p-content-${uuidv4()}`

const PContent = (props: any) => {
  const { flexWrap, isRow, alignItems, justifyContent, margin, border, height, width } = props
  const flexDirection = isRow ? 'row' : 'column'
  const { css } = useFela()

  return (
    <div key={key} className={css(content({ flexWrap, alignItems, flexDirection, justifyContent, margin, border, height, width }))}>
      {props.children}
    </div>
  )
}
export default PContent
