import React, { useRef } from 'react'
import { useFela } from 'react-fela'
import { pathspotPrimary, toastifyError, toastifySuccess, toastifyWarning } from '../../../../../webapp-lib/pathspot-react/styles/ts/colors'
import { v4 as uuidv4 } from 'uuid'

const componentKey = uuidv4()
const tableElement = (args: any = {}) => {
  const { textAlign, fontSize, fontStyle, fontWeight, color, width, backgroundColor } = args
  return {
    display: 'inline-flex !important',
    flexDirection: 'row',
    textAlign: textAlign || undefined,
    fontSize: fontSize || '1rem',
    fontStyle: fontStyle || 'normal',
    fontWeight: fontWeight || '500',
    color: color || pathspotPrimary,
    backgroundColor: backgroundColor ? backgroundColor : undefined,
    justifyContent: 'center',
    alignItems: 'center',
    width: width,
    borderRadius: '0.25rem',
  }
}

const PTableElement = (props: any) => {
  const {
    value: _value,
    item,
    TableElement,
    rowIdx,
    colIdx,
    width,
    valueKey,
    labelKey,
    label: _label,
    type,
    callback,
    readOnly,
    stateStyling: _stateStyling,
    cstyles,
  } = props
  const elementVal = _value === undefined ? (item === undefined ? undefined : valueKey === undefined ? undefined : item[valueKey]) : _value
  const label = _label ? _label : item ? item[labelKey] : ''
  let stateStyle = {}
  try {
    stateStyle = _stateStyling ? _stateStyling.states[item[_stateStyling.key]] : {}
  } catch (e) {
    stateStyle = {}
  }
  if (_stateStyling) {
    // console.log('State style is -----------------------------: ', stateStyle, _stateStyling, item)
  }
  // console.log('PTABLE ELEMENT elementVal, PROPS: ', item, valueKey, item ? item[valueKey] : item)
  const placeholder = type === 'text' ? '' : ''
  const { css } = useFela()
  return (
    <div key={`table-column-${valueKey}-${rowIdx}-${colIdx}-${componentKey}-div`} className={css(tableElement({ width: width || '10%' }))}>
      <TableElement
        key={`table-column-${valueKey}-${rowIdx}-${colIdx}-${componentKey}-element`}
        value={elementVal === undefined ? placeholder : elementVal}
        callback={callback}
        item={item}
        readOnly={readOnly}
        label={label}
        {...cstyles}
        {...stateStyle}
      />
    </div>
  )
}

export default PTableElement
