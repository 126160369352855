import React, { useMemo, useState } from 'react'
import { SCOPE } from '../../models/EnumeratedTypes'
import _ from 'lodash'
// import RGL, { Responsive, WidthProvider } from 'react-grid-layout'
import GridLayout, { ItemCallback, Layout } from 'react-grid-layout'

import { getValueFromFieldPath, RowBreakRatio, StyledSpinner } from '../..'
import { v4 as uuidv4 } from 'uuid'
import { Responsive, WidthProvider } from 'react-grid-layout'

// const ResponsiveGridLayout = WidthProvider(Responsive)
// const IntermediateGrid = ({ width, ...otherProps }: any) => {
//   console.log('Width for responsive is', width)

//   // console.log('current breakpoint:', Responsive.utils.getBreakpointFromWidth(yourBreakpoints, width))
//   return <Responsive width={width} {...otherProps} measureBeforeMount={true} />
// }
// const FullGrid = WidthProvider(IntermediateGrid)

// const ResponsiveReactGridLayout = WidthProvider(Responsive)
const componentKey = uuidv4()
const providerKey = uuidv4()
const keyIdx = 0

const generateHeader = (fields: any) => {
  //console.log('generateHeader: Fields are: ', fields);
  let idx = 0
  if (fields?.some((field: any) => field && field.header)) {
    return (
      <thead>
        <tr className="react-grid-header-item">
          {fields.map((field: any) => {
            return <th className="react-grid-header-cell-item" key={`${idx++}`} style={{ width: `${field.style.width}` }}>{`${field.header}`}</th>
          })}
        </tr>
      </thead>
    )
  } else {
    return <></>
  }
}

const getScopedField = (item: any, field: any, idx: any) => {
  switch (field.scope.type) {
    case SCOPE.SUBTABLE:
      const subFields = field.scope.attributes.fields

      const header = generateHeader(subFields)
      const itemValue = getItemFieldValue(item, field)
      // if (itemValue.length > 0)
      let subIdx = 0
      let subRowIdx = 0
      const body = itemValue.map((subItem: any) => {
        const subItems = subFields.map((subField: any) => {
          //console.log('Checking subfield: ', subField);
          const subFieldValue = getItemField(subItem, subField, subIdx++)
          //  //console.log('Subfields value is: ', subFieldValue);
          return subFieldValue
        })
        return (
          <tr key={`${componentKey}-${field.key}-${idx}-${subRowIdx++}-subtr`} className={`react-grid-table react-grid-item`} style={{ width: field.style.width }}>
            {subItems}
          </tr>
        )
      })
      return (
        <table key={`${componentKey}-${field.key}-${idx}-subtab`} className="react-grid-table">
          {header}
          <tbody>{body}</tbody>
        </table>
      )
    case SCOPE.SUBLIST:
      return
    case SCOPE.BUTTON_EXPAND:
    case SCOPE.BUTTON_LINK:
      return
    case SCOPE.JSX:
      //console.log('Item in scope jsx is: ', item[field.key]);
      return item[field.key]
    default:
      return
  }
}

const getItemFieldValue = (item: any, field: any) => {
  return field && field.path && field.path.length > 0 ? getValueFromFieldPath(item, field.path) : item[field.key]
}

const getItemField = (item: any, field: any, idx: any) => {
  if (field.scoped) {
    const scopedCell = (
      <td key={`${uuidv4()}-${field.key}-${idx}-scoped`} className="react-grid-cell-item" style={{ width: `${field.style.width}` }}>
        {getScopedField(item, field, idx)}
      </td>
    )
    // console.log('For item, scoped field is:', [item, scopedCell]);
    return scopedCell
  } else {
    const fieldValue = getItemFieldValue(item, field)
    const displayValue = fieldValue === null ? '-' : `${fieldValue}`
    const normalCell = (
      <td key={`${componentKey}-${field.key}-${idx}-normal`} className="react-grid-cell-item" style={{ width: `${field.style.width}` }}>
        {displayValue}
      </td>
    )
    //console.log('For item, normal field is:', [item, normalCell]);
    return normalCell
  }
}

const generateLayout = (p: any) => {
  const widthAsCols = p.nColsItemWidth
  const heightAsRows = p.nRowsItemHeight
  return p.items.map((item: any, i: any) => {
    const currentRow = Math.floor(i / (widthAsCols + 1))
    const currentColumn = i % (widthAsCols + 1)
    const layout = {
      x: widthAsCols * currentColumn,
      y: heightAsRows * currentRow,
      w: p.nColsItemWidth,
      h: heightAsRows,
      i: item.keytag,
    }
    return layout
  })
}

export const layoutStyle = {
  border: 'none',
  marginBottom: '6px',
  background: '#226fff',
  borderRadius: 0,
}

const PGrid = (props: any) => {
  const { containerWidth, margin, fixed, items, navProps, handlers, layoutHandlers } = props

  // console.log('props in PGrid list are: ', props)
  const layout = generateLayout({ ...props })

  const domElements = useMemo(() => {
    return items.map((item: any, idx: any) => {
      const cardData = _.cloneDeep(item)
      const GridComponent: any = navProps.domElement
      return <GridComponent key={item.keytag} item={cardData} changeHandlers={handlers} />
    })
  }, [items])

  if (containerWidth === null || containerWidth === undefined) {
    return <StyledSpinner />
  }
  // console.log('Container width is: ', containerWidth)

  return (
    <GridLayout
      className="layout react-grid-container"
      layout={layout}
      onLayoutChange={(layout: any) => {
        layoutHandlers.handleChange(layout)
      }}
      cols={12}
      width={containerWidth}
      height={30}
      isBounded={true}
      preventCollision={false}
      isResizable={false}
      margin={margin}
      isDraggable={fixed ? !fixed : true}
      {...props}
    >
      {domElements}
    </GridLayout>
  )
}
export default React.memo(PGrid)
