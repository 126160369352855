import { api } from '../../../api/api'
import { API_URL_LOG_IN, POWER_APP_URL } from '../../../api/constants'

export const logIn = async ({ ...values }) => {
  const apiReturn = api
    .noAuth()
    .url(`${API_URL_LOG_IN}`)
    .options({ credentials: 'include', mode: 'cors' })
    .post({ ...values })
    .json()
  //console.log('A
  //console.log('API Return for login is: ', apiReturn)
  return apiReturn
  // .then(api.zjson)
}
