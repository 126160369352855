import React from "react";
import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import { icons } from "./webapp-lib/pathspot-react/styles/assets/icons";
import "./polyfill";
import * as serviceWorker from "./serviceWorker";
import {store} from "./redux/store";
import useAuthContext from "./api/authentication/useAuthContext";
import {  LoginEvent } from "./api/authentication/AuthenticationContext";
import { checkTokenBeforeAppLaunch } from "./api/authentication/auth";
import{ createRenderer } from 'fela'
import{RendererProvider}from'react-fela'

React.icons = icons;


  window.addEventListener('storage', function (event) {
    if (event.key === LoginEvent.logout && event.newValue === null) {
      console.log("===== Got the log out event, logging out on this tab.. =====")
      const {userLogout} = useAuthContext()
      userLogout()
    }
  })


async function bootstrap() {
  //if new tab was opened or page was refreshed, we want to keep the user logged in
  //the function below will check for the cookie and based on that it may refresh
  //the token and have the user log in (silently)
  // const { silentlyRefreshToken } = useAuthContext()
  const initialAuthState = await checkTokenBeforeAppLaunch()
  const renderer = createRenderer()
  ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
          <RendererProvider renderer={renderer}>
            <App initialAuthState={initialAuthState}/>
          </RendererProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
  serviceWorker.unregister();
}

bootstrap();

