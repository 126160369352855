import { ModalFooterType } from './Types'
import { cancelButtonLight, continueButtonWarn, confirmButton } from '../../components/buttons/button-styles'

export const defaultModalState = {
  id: null,
  type: null,
  display: false,
  handles: {},
  dispatches: {},
  kwargs: {},
}
