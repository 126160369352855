//@ts-nocheck
export const convertArrayOfObjectsToCSV = (itemsObject: any, columnNames: any) => {
  let result: any
  let ctr: any
  let keys: any
  let columnDelimiter: any
  let lineDelimiter: any
  let data: any
  console.log('-------------------itemsObject is: ', itemsObject)
  console.log('-------------------Column names are: ', columnNames)
  data = itemsObject.data || null
  console.log('---------------------data is now: ', data)
  if (data === null || !data.length) {
    columnDelimiter = itemsObject.columnDelimiter || ','
    lineDelimiter = itemsObject.lineDelimiter || '\n'
    let columnNamesKeysArray = Object.keys(columnNames)
    result = ''
    result += columnNamesKeysArray.map((x: any) => columnNames[x]).join(columnDelimiter)
    result += lineDelimiter
    return result
  }

  columnDelimiter = itemsObject.columnDelimiter || ','
  lineDelimiter = itemsObject.lineDelimiter || '\n'

  let columnNamesKeysArray = Object.keys(columnNames)

  keys = Object.keys(data[0]).filter((key: string) => columnNamesKeysArray.includes(key)) //this filters out some fields that we wouldnt want in the CSV, such as dateRawUTC
  //keys = Object.keys(data[0])

  result = ''
  result += keys.map((x: any) => columnNames[x]).join(columnDelimiter)
  //result += columnNamesArray.join(columnDelimiter)
  result += lineDelimiter

  data.forEach((item: any) => {
    ctr = 0

    keys.forEach((key: any) => {
      if (ctr > 0) result += columnDelimiter
      let fn = item[key]
        ? item[key]
            .toString()
            .replace(/,/g, '')
            .replace(
              /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?(?:\u200d(?:[^\ud800-\udfff]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?)*/g,
              ''
            )
        : '-'
      result += fn.length > 0 ? fn : '[]'
      ctr++
    })

    result += lineDelimiter
  })

  return result
}

export const downloadCSV = (data: any, columnNames: any, fileName: string) => {
  let finalFileName = fileName + '.csv'
  finalFileName = finalFileName.replace(/ /g, '_')

  let link: any
  var csv = convertArrayOfObjectsToCSV({ data: [...data] }, columnNames)
  //console.log("[downloadCSV] data and csv are ", data, csv);

  link = document.createElement('a')
  link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv))

  link.setAttribute('download', finalFileName)
  link.click()
}

export const prepComplianceTableItemsForCSV = (complianceTableItems: any) => {
  return complianceTableItems.map((item: any) => {
    return {
      locationName: item.locationName,
      locationGroupName: item.locationGroupName,
      changeOverTime: item.changeOverTime + '%',
      avgDailyGoal: item.avgDailyGoal,
      avgCompliance: item.avgCompliance,
      highestCompliance: item.highestCompliance.location_name
        ? `${item.highestCompliance.value} on ${item.highestCompliance.date} at ${item.highestCompliance.location_name}`
        : `${item.highestCompliance.value} on ${item.highestCompliance.date}`,
      lowestCompliance: item.lowestCompliance.location_name
        ? `${item.lowestCompliance.value} on ${item.lowestCompliance.date} at ${item.lowestCompliance.location_name}`
        : `${item.lowestCompliance.value} on ${item.lowestCompliance.date}`,
    }
  })
}

export const downloadCSVTemplate = (columnNames: any) => {
  let columnDelimiter = ','
  let lineDelimiter = '\n'
  let link: any
  var csv = columnNames.join(columnDelimiter)
  csv += lineDelimiter
  link = document.createElement('a')
  link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv))
  link.setAttribute('download', 'template.csv')
  link.click()
}

export const convertCSVtoJSobject = (file: any) => {
  const reader = new FileReader()

  reader.onabort = () => console.log('file reading was aborted')
  reader.onerror = () => console.log('file reading has failed')
  reader.onload = () => {
    const csv = reader.result
    const lines = csv.split('\n')
    let result = []
    let headers = lines[0].split(',')

    for (let i = 1; i < lines.length; i++) {
      let obj: any = {}
      let currentline = lines[i].split(',')
      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j]
      }
      result.push(obj)
    }
    //result = JSON.stringify(result) //JSON
    // console.log(result)
    return result
  }

  reader.readAsText(file)
}
