import { SelectableItem } from '../../models/Types'

export const previousListItem = (searchList: any[], value: any) => {
  let prevIndex = searchList.indexOf(value) - 1
  prevIndex = prevIndex < 0 ? searchList.length - 1 : prevIndex
  //console.log("Previous index is: ", prevIndex)
  return searchList[prevIndex]
}

export const linspace = (start: number, stop: number, step: number) => {
  const aLen = Math.ceil((stop - start) / step) + 1
  let idx = 0
  return Array.apply(null, Array(aLen)).map((val: any) => start + step * idx++)
}

export const buildSelectableArray = (inputArray: Array<any>, labelKey: string, valueKey: string) => {
  const selectableItems = inputArray.map((item: any) => {
    const selectableItem: SelectableItem<typeof item> = {
      label: item[labelKey],
      value: item[valueKey],
      source: { ...item } as typeof item,
    }
    return selectableItem
  })
  return selectableItems && selectableItems.length ? [...selectableItems] : []
}
