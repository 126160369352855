import * as React from 'react'

const PathSpotDoubleArrowRightRaw = (props: any) => {
  const { fillColor, strokeColor, checked, className, viewBox, width, height } = props
  return (
    <svg viewBox={viewBox || '0 0 508.085 408'} className={className || 'p-icon-5xl'}>
      <path d="M132.095 204.606c-1.623-1.66-2.6-2.679-3.597-3.676C89.079 161.496 49.674 122.049 10.22 82.651 4.772 77.21 1.066 70.979.22 63.234c-.971-8.884 1.23-16.966 7.345-23.462C17.411 29.311 27.467 19.034 37.758 9.01 47.465-.445 60.661-2.569 72.471 3.162c3.146 1.527 6.14 3.725 8.627 6.195 25.892 25.715 51.665 51.55 77.474 77.348 31.071 31.058 62.147 62.112 93.215 93.173 1.369 1.369 2.734 2.747 4.014 4.198 10.052 11.393 10.19 28.69.004 39.976-5.526 6.122-11.536 11.81-17.373 17.648-52.038 52.047-104.092 104.078-156.118 156.137-4.724 4.727-10.067 8.236-16.727 9.501-10.189 1.936-19.307-.323-26.866-7.47-7.181-6.79-14.05-13.91-21.054-20.887-2.672-2.662-5.297-5.371-8.01-7.99-12.867-12.42-12.662-32.221-.006-44.789 39.707-39.433 79.193-79.089 118.782-118.641 1.005-1.004 2.217-1.799 3.661-2.954z" />
      <path d="M376.332 203.933c-.757-.812-1.418-1.566-2.125-2.273-39.501-39.496-79.008-78.986-118.503-118.488-1.654-1.655-3.342-3.311-4.76-5.161-9.068-11.836-8.256-28.435 2.004-39.256 5.187-5.471 10.612-10.717 15.934-16.06 4.387-4.404 8.747-8.836 13.182-13.192 9.813-9.637 22.895-11.989 35.191-6.366 3.667 1.677 6.728 4.198 9.555 7.024 30.712 30.702 61.42 61.41 92.134 92.11 26.244 26.232 52.476 52.476 78.76 78.668 4.586 4.57 8.036 9.729 9.49 16.085 2.267 9.91.237 18.931-6.46 26.538-4.976 5.652-10.483 10.84-15.815 16.174-52.325 52.34-104.669 104.661-156.986 157.008-4.23 4.233-8.751 7.968-14.617 9.666-10.784 3.122-20.657 1.296-29.129-6.119-4.976-4.355-9.496-9.234-14.186-13.912-5.702-5.686-11.461-11.321-17.029-17.137-8.645-9.03-10.74-22.631-5.38-33.788 1.647-3.428 3.858-6.448 6.556-9.144 19.975-19.957 39.929-39.936 59.887-59.909 20.031-20.046 40.061-40.093 60.088-60.143.715-.716 1.395-1.467 2.208-2.325z" />
    </svg>
  )
}

const PathSpotDoubleArrowRight = React.memo(PathSpotDoubleArrowRightRaw)
export default PathSpotDoubleArrowRight
