export const API_URL = process.env.REACT_APP_API_URL
// export const POWER_APP_URL = process.env.POWER_APP_API_URL
// export const POWER_APP_URL = 'https://4fbfc8wmgc.execute-api.us-east-1.amazonaws.com/power_app_preprod'
export const POWER_APP_URL = 'https://p3l8v6clr8.execute-api.us-east-1.amazonaws.com/power_app_staging'
// export const POWER_APP_URL = 'https://power-app.pathspot-engineering.com'

export enum ENV {
  PROD = 'production',
  STAGING = 'staging',
  DEV = 'development',
  PREPROD = 'preproduction',
}

export enum USER_RENDER_MODE {
  DEPARTMENTS = 'departments',
  DEFAULT = 'default',
}
export enum PERMISSIONS {
  LOCATION_GROUP = 'LOCATION_GROUP',
  DEVICE_DATA_VIEWER = 'DEVICE_DATA_VIEWER',
  ANONYMOUS = 'ANONYMOUS',
  PARTIAL_ANONYMOUS = 'PARTIAL_ANONYMOUS',
  BASIC_COUNT_ONLY = 'BASIC_COUNT_ONLY',
  HANDS_PRESENT_ONLY = 'HANDS_PRESENT_ONLY',
  ADMIN = 'ADMIN',
}
export enum DISPLAY_PAGES {
  ITEM_VIEW_HANDWASHES = 'ITEM_VIEW_HANDWASHES',
  ITEM_VIEW_COMPLIANCE = 'ITEM_VIEW_COMPLIANCE',
  ITEM_UPLOAD_EMPLOYEES = 'ITEM_UPLOAD_EMPLOYEES',
  ITEM_VIEW_DEVICES = 'ITEM_VIEW_DEVICES',
  ITEM_VIEW_SCAN_IMAGES = 'ITEM_VIEW_SCAN_IMAGES',
  ITEM_SET_GOALS = 'ITEM_SET_GOALS',
  ITEM_VIEW_USERS = 'ITEM_VIEW_USERS',
  ITEM_VIEW_EMPLOYEES = 'ITEM_VIEW_EMPLOYEES',
  ITEM_EDIT_EMPLOYEES = 'ITEM_EDIT_EMPLOYEES',
  ITEM_VIEW_FORM_RESPONSES = 'ITEM_VIEW_FORM_RESPONSES',
  ITEM_VIEW_OVERVIEW = 'ITEM_VIEW_OVERVIEW',
  ITEM_DEVELOPER_OPTIONS = 'ITEM_DEVELOPER_OPTIONS',
  ITEM_VIEW_SENSORS = 'ITEM_VIEW_SENSORS',
  ITEM_EDIT_SENSORS = 'ITEM_EDIT_SENSORS',
  ITEM_APPROVE_COOLDOWN = 'ITEM_APPROVE_COOLDOWN',
  ITEM_VIEW_COOLDOWN = 'ITEM_VIEW_COOLDOWN',

  ITEM_GENERATE_REPORTS = 'ITEM_GENERATE_REPORTS',
}
// "REACT_APP_API_URL": "https://gckpgclusj.execute-api.us-east-1.amazonaws.com/app_api_staging",

export const API_URL_LOG_IN = `${API_URL}/auth/login`
export const API_URL_REFRESH_TOKEN = `${API_URL}/auth/refresh`
export const API_URL_COMPLIANCE_DATA = `${API_URL}/compliance`
export const API_URL_GOALS_DATA = `${API_URL}/goals`
export const API_URL_MY_LOCATIONS_WITH_GOALS_SUMMARY = `${API_URL}/my-locations`
export const API_URL_MY_LOCATION_GROUPS_WITH_LOC_NAMES = `${API_URL}/my-location-groups`
export const API_URL_ALL_USERS = `${API_URL}/my-users`
export const API_URL_GET_FILTERS_FOR_GOALS_DASHBOARD = `${API_URL}/filters?pageName=GOALS_DASHBOARD`
export const API_URL_GET_FILTERS_FOR_COMP_DASHBOARD = `${API_URL}/filters?pageName=COMPLIANCE_DASHBOARD`
export const API_URL_GET_FILTERS_FOR_DEVICES_DASHBOARD = `${API_URL}/filters?pageName=DEVICES_DASHBOARD`
export const API_URL_GET_FILTERS_FOR_SCAN_IMAGES_DASHBOARD = `${API_URL}/filters?pageName=SCAN_IMAGES_DASHBOARD`
export const API_URL_GET_FILTERS_FOR_HANDWASHES_DASHBOARD = `${API_URL}/filters?pageName=HANDWASHES_DASHBOARD`
export const API_URL_GET_FILTERS_FOR_WELLNESS_DASHBOARD = `${API_URL}/filters?pageName=FORM_DASHBOARD`
export const API_URL_GET_FILTERS_FOR_SENSOR_OVERVIEW = `${API_URL}/filters?pageName=SENSORS_OVERVIEW`
export const API_URL_GET_FILTERS_FOR_SENSORS_PROCESSES = `${API_URL}/filters?pageName=SENSORS_PROCESSES`

export const API_URL_GET_FILTERS_FOR_SENSORS_GRAPH = `${API_URL}/filters?pageName=SENSORS_GRAPH`
export const API_URL_GET_FILTERS_FOR_SENSORS_ALERTS = `${API_URL}/filters?pageName=SENSORS_ALERTS`

export const API_URL_DEVICE_ACTIVITY = `${API_URL}/devices-activity`
export const API_URL_HANDWASHES_DASHBOARD = `${API_URL}/handwashes-dashboard`
export const API_URL_HW_DASHBOARD_SECTION = `${API_URL}/handwashes-dashboard/`
export const API_URL_HW_DASHBOARD_MULTI_LEVEL_CHARTS = `${API_URL_HANDWASHES_DASHBOARD}/multi-level-charts-section`
export const API_URL_HW_DASHBOARD_ACTIVITY_CHARTS = `${API_URL_HANDWASHES_DASHBOARD}/activity-charts-section`
export const API_URL_LOCATIONS_GOAL_SUMMARY = `${API_URL}/goals-list`
export const API_URL_LOCATIONS_AND_LOCATION_GROUPS = `${API_URL}/goals-locations-and-location-groups`
export const API_URL_GOALS_SETTING = `${API_URL}/goals`
export const API_URL_BULK_UPLOAD = `${API_URL}/bulk-upload`
export const API_URL_LOC_NAMES_FOR_UPLOAD_EMPLOYEES = `${API_URL}/locations-for-bulk-upload`
export const API_URL_REQUEST_PASSWORD_RESET = `${API_URL}/auth/forgot-password`
export const API_URL_SET_NEW_PASSWORD = `${API_URL}/auth/set-new-password`
export const API_URL_CHANGE_CURRENT_PASSWORD = `${API_URL}/auth/change-my-password`
export const API_URL_SCAN_IMAGES = `${API_URL}/scan-images`
export const API_URL_INTERACTION_LOGGING = `${API_URL}/logging`
export const API_URL_ALL_EMPLOYEES = `${API_URL}/my-employees`
export const API_URL_FORMS = `${API_URL}/forms`
export const API_URL_SENSORS = `${API_URL}/sensors`

// new overview page, will be updated once the page is finished with design
export const API_URL_OVERVIEW = `/overview`
export const SHOW_OVERVIEW = !API_URL?.includes('pathspot.app')

export const API_URL_WELLNESS_CHECK_DASHBOARD = `${API_URL}/forms/overview`
export const API_URL_WELLNESS_CHECK_ANSWER_BREAKDOWN = `${API_URL}/forms/overview/answer_breakdown`
export const API_URL_WELLNESS_CHECK_EMPLOYEE_BREAKDOWN = `${API_URL}/forms/question/employee_breakdown`

export const API_URL_API_MANAGEMENT = `${API_URL}/api-management`

// sensors GET

export const API_URL_SENSORS_GET_MODELS = `${API_URL_SENSORS}/models`
export const API_URL_SENSORS_GET_LIST = `${API_URL_SENSORS}/list`
export const API_URL_SENSORS_CRUD = `${API_URL_SENSORS}/crud`
export const API_URL_SENSORS_PERMISSIONS = `${API_URL_SENSORS}/permissions`
export const API_URL_SENSORS_USERS_BY_LOCATION = `${API_URL_SENSORS}/users`
export const API_URL_SENSORS_TEMPLATES = `${API_URL_SENSORS}/templates`
export const API_URL_SENSORS_ALERTS = `${API_URL}/sensor_alerts`

export const API_URL_SENSORS_PROCESSES_OVERVIEW = `${API_URL}/cooldown/overview`
export const API_URL_SENSORS_PROCESSES_CANCEL = `${API_URL}/cooldown/cancel`
export const API_URL_SENSORS_PROCESSES_APPROVE = `${API_URL}/cooldown/approve`
export const API_URL_SENSORS_PROCESSES_COMMENT = `${API_URL}/cooldown/comment`
export const API_URL_SENSORS_PROCESSES_START = `${API_URL}/cooldown/start`
export const API_URL_SENSORS_PROCESSES_LAST_READING = `${API_URL}/cooldown/last-reading`
export const API_URL_SENSORS_PROCESSES_FOOD_ITEMS = `${API_URL}/cooldown/food-items`
export const API_URL_SENSORS_PROCESSES_SENSOR_ITEMS = `${API_URL}/cooldown/sensors`

export const SENSOR_DASHBOARD_PATHNAME = 'sensors-overview'
export const SENSOR_DATA_REPORTING_PATHNAME = 'sensor-data-reporting'
