import React, { BaseSyntheticEvent } from 'react'
import { useFela } from 'react-fela'
import { container, debugBorder } from '../../styles/containers'
import { v4 as uuidv4 } from 'uuid'
import { inputText, tableInput } from '../../styles/text'
import PElement from '../containers/PElement'
import { flexDirection } from '../../styles/css-properties'
const componentUUID = uuidv4()
const PTextInput = (props: any) => {
  // console.log('////////////////////////Pitem text props are: ', props)
  const {
    displayText: _displayText,
    placeholder: _placeholder,
    value,
    alignItems,
    justifyContent,
    width,
    fontSize,
    color,
    fontWeight,
    margin,
    padding,
    border,
    readOnly,
    item,
    callback,
  } = props
  const { css } = useFela()
  const placeholder = _displayText || _placeholder || value
  const key = `${placeholder}-${componentUUID}`

  return (
    <PElement
      showComment
      isRow
      margin={margin}
      width={width || '96%'}
      justifyContent={justifyContent}
      alignItems={alignItems}
      flexWrap={'wrap'}
    >
      <input
        key={key}
        className={css(inputText({ fontSize, color, fontWeight, flexDirection: flexDirection || 'row', margin, padding, border }))}
        defaultValue={`${placeholder || value}`}
        disabled={readOnly}
        onBlur={(e: BaseSyntheticEvent) => {
          callback(e.target.value, item)
        }}
      />
    </PElement>
  )
}

export default PTextInput
