import React from 'react'
import { useFela } from 'react-fela'
import PElement from '../containers/PElement'
import {
  formControlBackground,
  grayInput,
  grayInputBackground,
  grayInputBorder,
  pathspotOffWhite,
  pathspotPrimary,
  pathspotWhite,
} from '../../../styles/ts/colors'
import DatePicker from 'react-datepicker'
import { Fela } from '../../..'
import { FD } from '../../api/fela.constants'
import { PathSpotDecrement, PathSpotIncrement } from '../../../styles/assets/icons/pathspot/catalog'
import 'react-datepicker/dist/react-datepicker.css'

const daySelector = (args: any) => {
  if (FD.DAYSELECT) console.log('Args in dayselector are: ', args)
  const { height, border, color, backgroundColor, fontSize, textAlign, justifyContent, alignItems } = args
  const { borderRadius, borderColor, borderWidth, borderStyle } = border || {}
  return {
    color: color || grayInput,
    backgroundColor: backgroundColor || formControlBackground,
    height: height || '2rem',
    justifyContent: justifyContent || 'center',
    textAlign: textAlign || 'center',
    fontSize: fontSize || '1.15rem',
    borderColor: borderColor || grayInputBorder,
    borderWidth: borderWidth || '1px',
    borderRadius: borderRadius || '0.25rem',
    borderStyle: borderStyle || 'solid',
    boxShadow:
      '0 1px 1px 0 rgba(var(--elevation-base-color), .14), 0 2px 1px -1px rgba(var(--elevation-base-color), .12), 0 1px 3px 0 rgba(var(--elevation-base-color), .20)',
  }
}

const PDaySelector = (props: any) => {
  const { selectedDate, setSelectedDate, width, border, height, onNextDay, onPreviousDay } = props
  const { css } = useFela()

  return (
    <PElement isRow width={width} border={{ ...border }} justifyContent="center" alignItems="center">
      <Fela.PIconButton
        PathSpotIcon={PathSpotDecrement}
        showComment
        iconName="PathSpotDecrement"
        width="25%"
        button={{ height: height, color: grayInput, backgroundColor: formControlBackground }}
        iconStrokeColor={grayInput}
        iconFillColor={grayInput}
        onClick={onPreviousDay}
      />
      <DatePicker
        className={css(daySelector({ height }))}
        selected={selectedDate}
        onSelect={setSelectedDate}
        onChange={setSelectedDate} //only when value has changed
      />
      <Fela.PIconButton
        PathSpotIcon={PathSpotIncrement}
        showComment
        iconName="PathSpotIncrement"
        width="25%"
        button={{ height: height, color: grayInput, backgroundColor: formControlBackground }}
        iconStrokeColor={grayInput}
        iconFillColor={grayInput}
        onClick={onNextDay}
      />{' '}
    </PElement>
  )
}
export default PDaySelector
