import { grayInput, grayInputBackground, grayInputBorder, pathspotPrimary, pathspotWhite, toastifyError } from '../../styles/ts/colors'
import * as css from './css-properties'

export const baseText = (args: any) => {
  const { color, fontSize, fontStyle, fontWeight } = args
  return {
    ...css.inlineFlex,
    color: color || pathspotPrimary,
    fontSize: fontSize || '1rem',
    fontStyle: fontStyle || 'normal',
    fontWeight: fontWeight || '600',
  }
}
export const pageTitle = (args: any) => {
  const { color, fontSize, fontStyle, fontWeight, width } = args

  return {
    ...css.inlineFlex,
    flexDirection: 'row',
    flexWrap: 'noWrap',
    color: color || pathspotPrimary,
    fontSize: fontSize || '2rem',
    fontStyle: args?.fontStyle ? args.fontStyle : 'normal',
    fontWeight: args?.fontWeight ? args.fontWeight : 'bolder',
  }
}
export const buttonText = (args: any) => {
  const { color, fontSize, fontStyle, fontWeight, width } = args

  return {
    ...css.inlineFlex,
    flexDirection: 'row',
    flexWrap: 'noWrap',
    color: color || pathspotWhite,
    fontSize: fontSize || '1.25rem',
    fontStyle: fontStyle || 'normal',
    fontWeight: fontWeight || 'bolder',
    width: width,
  }
}
export const itemText = (args: any) => {
  const { color, backgroundColor, fontSize, fontStyle, fontWeight, width, textAlign, margin } = args

  return {
    ...css.inlineFlex,
    flexDirection: 'row',
    flexWrap: 'noWrap',
    color: color || pathspotPrimary,
    textAlign: textAlign || 'center',
    fontSize: fontSize || '1rem',
    fontStyle: fontStyle || 'normal',
    fontWeight: fontWeight || '600',
    justifyContent: 'center',
    alignItems: 'center',
    width: width,
    backgroundColor: backgroundColor,
    ...margin,
  }
}
export const tableInput = (args: any) => {
  const { color, fontSize, fontStyle, fontWeight, width, textAlign, margin, borderWidth, borderColor, borderStyle, borderRadius } = args

  return {
    ...css.inlineFlex,
    flexDirection: 'row',
    flexWrap: 'noWrap',
    color: color || pathspotPrimary,
    textAlign: textAlign || 'center',
    fontSize: fontSize || '1rem',
    fontStyle: fontStyle || 'normal',
    fontWeight: fontWeight || '600',
    justifyContent: 'center',
    alignItems: 'center',
    width: width || '100%',
    ...margin,
    borderWidth: borderWidth || '1px',
    borderColor: borderColor || pathspotPrimary,
    borderStyle: borderStyle || 'solid',
    borderRadius: borderRadius || '0.25rem',
  }
}
export const fieldlabel = (args: any) => {
  const { textAlign, color, lineHeight, borderStyle, borderWidth, borderColor, borderRadius, margin } = args
  return {
    //whitespace, overflow-wrap
    ...baseText(args),
    textAlign: textAlign,
    color: color || pathspotPrimary,
    lineHeight: lineHeight,
    borderWidth: borderWidth || '0px',
    borderColor: borderColor || pathspotPrimary,
    borderStyle: borderStyle || 'solid',
    borderRadius: borderRadius || '1rem',
    ...margin,
  }
}
export const inputText = (args: any) => {
  const {
    fontSize,
    fontStyle,
    fontWeight,
    textAlign,
    color,
    backgroundColor,
    justifyContent,
    alignItems,
    flexWrap,
    flexDirection,
    width,
    height,
    margin,
    border,
  } = args
  const { borderWidth, borderColor, borderStyle, borderRadius } = border || {}
  return {
    display: 'inline-flex !important',
    flexDirection: flexDirection || 'row',
    flexWrap: flexWrap || 'noWrap',
    color: color || grayInput,
    backgroundColor: backgroundColor || grayInputBackground,
    textAlign: textAlign || 'center',
    fontSize: fontSize || '1rem',
    fontStyle: fontStyle || 'normal',
    fontWeight: fontWeight || '600',
    justifyContent: justifyContent || 'center',
    alignItems: alignItems || 'center',
    width: width || '100%',
    height,
    ...margin,
    ...border,
    borderWidth: borderWidth || '1px',
    borderColor: borderColor || grayInputBorder,
    borderStyle: borderStyle || 'solid',
    borderRadius: borderRadius || '0.25rem',
  }
}
