import * as React from 'react'

const PathSpotFolderRaw = (props: any) => {
  const { fillColor, strokeColor, checked, className, viewBox, width, height } = props
  return (
    <svg viewBox={viewBox || '0 0 510 339.05'} className={className || 'p-icon-5xl'}>
      <path
        d="M510 192.846c-5.894 10.9-11.543 21.94-17.729 32.671-15.963 27.694-32.241 55.206-48.238 82.881-9.209 15.932-22.405 26.214-40.62 29.631-3.47.651-7.054.977-10.585.979-118.003.05-236.006.048-354.009.033-13.491-.002-23.44-9.695-22.62-22.518.236-3.694 1.625-7.621 3.496-10.857 20.866-36.081 41.949-72.036 62.934-108.049 9.491-16.288 23.495-26.019 42.082-29.006 3.694-.594 7.506-.633 11.263-.634 115.806-.031 231.613.072 347.418-.147 14.734-.028 23.376 5.71 26.608 18.111v6.905z"
        fill={fillColor || '#27aae1'}
      />
      <path
        d="M0 38.926c1.56-5.059 2.447-10.459 4.805-15.114C12.066 9.478 24.068 1.7 40.086.204 41.748.049 43.43.063 45.103.063c45.61-.007 91.22.016 136.83-.063 2.527-.004 4.224.792 5.968 2.547 17.318 17.439 34.741 34.774 52.064 52.208 1.674 1.685 3.341 2.386 5.73 2.382 45.924-.067 91.848-.108 137.772-.021 18.322.035 31.875 8.274 39.86 24.82 2.041 4.228 3.54 9.142 3.676 13.787.452 15.452.171 30.925.171 46.906h-4.192c-95.195 0-190.391-.017-285.586.011-28.061.008-51.365 10.592-68.805 32.628-6.308 7.97-11.153 17.136-16.333 25.952-16.826 28.641-33.493 57.374-50.241 86.061-.566.969-1.339 1.817-2.016 2.721L0 38.926z"
        fill={fillColor || '#ed1c24'}
      />
    </svg>
  )
}

const PathSpotFolder = React.memo(PathSpotFolderRaw)
export default PathSpotFolder
