import React, { useLayoutEffect, useRef } from 'react'
import { useFela } from 'react-fela'
import { element } from '../../styles/containers'
import { v4 as uuidv4 } from 'uuid'
const key = `p-element-${uuidv4()}`

const PElement = (props: any) => {
  const {
    flexWrap,
    isRow,
    alignItems,
    justifyContent,
    margin,
    border,
    width,
    showComment,
    minWidth: _minWidth,
    widthIsMin,
    maxWidth,
    height,
    maxHeight,
  } = props
  // if (showComment) console.log('props in PElement is: ', props)
  const minWidth = widthIsMin ? width : _minWidth
  const { css } = useFela()
  return (
    <div
      key={key}
      className={css(
        element({
          flexWrap,
          flexDirection: isRow ? 'row' : 'column',
          alignItems,
          justifyContent,
          margin,
          border,
          width,
          height,
          minWidth,
          maxWidth,
        })
      )}
    >
      {props.children}
    </div>
  )
}
export default PElement
