import { TypedRender } from '../../models/Types'
import { Dispatch, AnyAction } from 'redux'

export enum MODAL_ACTION {
  SHOW = 'show',
  HIDE = 'hide',
  CLEAR = 'clear',
}
export enum ModalFooterType {
  CANCEL_CONTINUE_BUTTONS = 'cancelContinueButtons',
  SAVE_CANCEL_BUTTONS = 'saveCancelButtons',
  CONFIRM_BUTTON = 'confirmButton',
  TEXT = 'text',
}

export interface ModalContent {
  title?: any
  header?: any
  body?: any
  footer?: any
}
export interface ModalState {
  key?: any
  type?: any
  display?: boolean
  kwargs?: any
  content?: ModalContent
}

export const emptyModal: TypedRender = {
  display: false,
  content: {
    body: '',
    title: '',
  },
  handleRef: undefined,
}

export const setModalError = (modal: TypedRender, error: Error): void => {}

export type ModalProps = {
  contextState?: any
  dispatcher?: Dispatch<AnyAction>
  actions?: any
  handlers?: any
  data?: any
  context?: string | null
  modalState?: ModalState
}
export interface ContextModalState {
  showModal: boolean
  context: any
  contextState?: any
  payload?: any
  options?:
    | {
        [T in string]: Array<any>
      }
    | null
  callbacks?:
    | {
        [T in string]: () => void
      }
    | null
}
