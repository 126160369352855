import React from 'react'
import { useFela } from 'react-fela'
import PFieldLabel from '../text/PFieldLabel'
import PDropdownSelect from '../selectors/PDropdownSelect'
import PContent from '../containers/PContent'

const PLabeledSelector = (props: any) => {
  const { fieldLabel, options, placeholder, value, onChange, width, first, labelIndent, labelWidth, isMulti } = props
  // console.log('######################### Props in PLabeledSelector are: ', props)
  return (
    <PContent
      isRow
      width={width}
      alignItems="center"
      justifyContent="space-between"
      margin={{ marginBottom: '1rem', marginTop: first ? '1rem' : undefined }}
    >
      <PFieldLabel
        label={fieldLabel}
        alignItems="center"
        justifyContent="flex-start"
        marginLeft={labelIndent || '0rem'}
        width={labelWidth || '33%'}
      />
      <PDropdownSelect width="67%" options={options} onChange={onChange} isMulti={isMulti} placeholder={placeholder} />
    </PContent>
  )
}
export default PLabeledSelector
