import React from 'react'
import { useFela } from 'react-fela'
import { container, debugBorder } from '../../styles/containers'
import { fieldlabel } from '../../styles/text'
import { v4 as uuidv4 } from 'uuid'
import PContainer from '../containers/PContainer'
import { grayInput, grayInputBackground, pathspotGreen } from '../../../styles/ts/colors'
const componentUUID = uuidv4()

export const pInputPrepend = (args: any) => {
  const {
    color,
    fontSize,
    fontStyle,
    fontWeight,
    width,
    textAlign,
    margin,
    backgroundColor,
    borderWidth,
    borderColor,
    borderStyle,
    borderRadius,
    height,
  } = args

  return {
    display: 'inline-flex !important',
    height: height || '2.35rem',
    flexDirection: 'row',
    flexWrap: 'noWrap',
    color: color || grayInput,
    backgroundColor: backgroundColor || grayInputBackground,
    textAlign: textAlign || 'center',
    fontSize: fontSize || '1rem',
    fontStyle: fontStyle || 'normal',
    fontWeight: fontWeight || '500',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    borderWidth: borderWidth || '1px',
    borderColor: borderColor || grayInputBackground,
    borderStyle: borderStyle || 'solid',
    // borderBottomRightRadius: borderRadius || '0rem',
    // borderTopRightRadius: borderRadius || '0rem',
    // borderBottomLeftRadius: borderRadius || '0.25rem',
    // borderTopLeftRadius: borderRadius || '0.25rem',
    ...margin,
  }
}

const borderCss = (args: any) => {
  const {
    borderRadius,
    borderRadiusLeft,
    borderTop,
    borderRadiusRight,
    borderBottom,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
  } = args
  return {
    borderRadius: `${borderRadius ? borderRadius : '0'} !important`,
    borderBottomRightRadius: borderRadiusRight ? (borderBottomRightRadius ? borderBottomRightRadius : borderRadius) : '0.25rem',
    borderTopRightRadius: borderRadiusRight ? (borderTopRightRadius ? borderTopRightRadius : borderRadius) : '0.25rem',
    borderBottomLeftRadius: borderRadiusLeft ? (borderBottomLeftRadius ? borderBottomLeftRadius : borderRadius) : '0.25rem',
    borderTopLeftRadius: borderRadiusLeft ? (borderTopLeftRadius ? borderTopLeftRadius : borderRadius) : '0.25rem',
    borderColor: pathspotGreen,
    borderWidth: '2px',
    borderStyle: 'solid',
  }
}
const PInputPrepend = (props: any) => {
  const { label, alignItems, justifyContent, marginRight, marginLeft, width, height, borderRadiusLeft, borderRadiusRight, borderRadius } =
    props
  const { css } = useFela()
  const key = `${label}-${componentUUID}`
  console.log('PInputPrepend width is: ', width)
  return (
    <PContainer
      isRow
      showComment
      comment="PInputPrepend Container"
      // height={height || '1.85rem'}
      alignItems={alignItems}
      justifyContent={justifyContent}
      margin={{ marginRight, marginLeft }}
      width={width || '20%'}
      border={{ ...debugBorder() }}
    >
      <span
        className={css(
          pInputPrepend({ height: height }),
          borderCss({
            borderRadiusLeft: borderRadiusLeft || true,
            borderRadiusRight: borderRadiusRight || true,
            borderRadius: borderRadius || '0.25rem',
          })
        )}
      >{`${label}`}</span>
    </PContainer>
  )
}

export default PInputPrepend
