import { API_URL_REFRESH_TOKEN, DISPLAY_PAGES } from '../constants'
import Cookies from 'js-cookie'
import { api } from '../api'
import { AuthContext, AuthenticationStatus, LoginEvent } from './AuthenticationContext'
import { CurrentUser } from './auth.types'
import history from '../history'
import _ from 'lodash'

export const userLogout = () => {
  Cookies.remove(LoginEvent.loginCookie)
  localStorage.removeItem(LoginEvent.logout)
  const currentPath = history.location.pathname
  const allowedPaths = ['/email-unsubscribed', '/set-new-password', '/initial-password']
  if (!allowedPaths.includes(currentPath)) {
    history.push('/login')
  }
}

export const displayPagesChanged = (currSideBar: any, respSideBar: any) => {
  if (!currSideBar !== !respSideBar) return true
  if (currSideBar.length != respSideBar.length) return true
  const respSet: any = new Set(respSideBar)
  const respEvery: boolean = currSideBar?.every((page: any) => respSet.has(page))
  if (!respEvery) return true
  const currSet: any = new Set(currSideBar)
  const currEvery: boolean = respSideBar.every((page: any) => currSet.has(page))
  if (!currEvery) return true
  return false
}
export const checkTokenBeforeAppLaunch = async () => {
  let reLogInNeeded = true
  try {
    // console.log('looking for a cookie in initializer')
    if (Cookies.get(LoginEvent.loginCookie)) {
      // console.log('===== In initializer, found the cookie. Trying to refresh the token.. =====', Cookies.get(LoginEvent.loginCookie))
      const initializerLoginResponse = await api
        .noAuth()
        .url(`${API_URL_REFRESH_TOKEN}`)
        .options({ credentials: 'include', mode: 'cors', cookie: Cookies.get(LoginEvent.loginCookie) })
        .post()
        .json()
        .then(api.zjson)
      // console.log('initializerLoginResponse login response is: ', initializerLoginResponse)
      // console.log('Iniitializer login response is: ', _.cloneDeep(initializerLoginResponse))

      if (initializerLoginResponse && initializerLoginResponse.accessToken) {
        // console.log('Made it inside the point to get the accessToken')
        const { userId, userEmail, firstName, lastName, accessToken, accessTokenExpMs, sidebarItems, developerOptions, temperatureInC } =
          initializerLoginResponse
        api.setToken(accessToken)
        Cookies.set(LoginEvent.loginCookie, AuthenticationStatus.loggedIn)
        // console.log('Cookie set in initializer')
        localStorage.setItem(LoginEvent.logout, AuthenticationStatus.loggedOut + Math.random())
        // console.log('logout event set in initializer')
        reLogInNeeded = false
        // console.log('===== In initializer, refresh was successful, no re-login needed =====')
        return {
          authState: {
            currentUser: { userId, userEmail, firstName, lastName } as CurrentUser,
            accessToken,
            currentContext: AuthContext.loggedIn,
            tokenExpiration: accessTokenExpMs,
            sidebarItems,
            displayPages: developerOptions ? [...sidebarItems, DISPLAY_PAGES.ITEM_DEVELOPER_OPTIONS] : [...sidebarItems],
            developerOptions,
            temperatureInC,
            initialized: true,
          },
          success: true,
        }
      } else {
        Cookies.remove(LoginEvent.loginCookie)
        localStorage.removeItem(LoginEvent.logout)
        const currentPath = history.location.pathname
        const allowedPaths = ['/email-unsubscribed', '/set-new-password', '/initial-password', '/login']
        if (!allowedPaths.includes(currentPath)) {
          history.push('/login')
        }
        return { success: false }
      }
    } else {
      userLogout()
    }
  } catch (e: any) {
    return { success: false }
  }
}
