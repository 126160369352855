import { pathspotPrimary, pathspotWhite, toastifyError } from '../../styles/ts/colors'
import * as css from './css-properties'

// export const maxWidth = (args: any = { width: null }) => {
//   const { width, offset } = args
//   const widthValue = width ? (width === null ? '100%' : width) : '100%'
//   const widthExp = offset ? `calc(${widthValue} - ${offset})` : widthValue
//   console.log('Width value is: ', widthExp)
//   return widthExp
// }

export const maxWidthClass = (args: any = { width: null }) => {
  const { width, offset } = args
  const widthValue = width ? (width === null ? '100%' : width) : '100%'
  const widthExp = offset ? `calc(${widthValue}-${offset})` : widthValue
  return {
    width: widthExp,
  }
}

export const border = (args: any) => {
  return {
    borderWidth: args?.borderWidth ? args.borderWidth : undefined,
    borderColor: args?.borderColor ? args.borderColor : pathspotPrimary,
    borderStyle: args?.borderStyle ? args.borderStyle : 'solid',
    borderRadius: args?.borderRadius ? args.borderRadius : '1rem',
  }
}
// border={{ borderWidth: '2px', borderStyle: 'solid', borderColor: toastifyError }
export const debugBorder = (args: any = {}) => {
  const { borderColor, borderStyle, borderWidth, borderRadius } = args
  return {
    borderWidth: borderWidth || '1px',
    borderColor: borderColor || toastifyError,
    borderStyle: borderStyle || 'solid',
    borderRadius: borderRadius || '0rem',
  }
}

export const wrapper = (args: any) => {
  const { flexDirection, flexWrap, margin, width, padding, border, justifyContent, alignItems } = args
  return {
    display: 'flex !important',
    flexDirection: flexDirection || 'column',
    flexWrap: flexWrap || 'nowrap',
    width: width || '100%',
    justifyContent: justifyContent,
    alignItems: alignItems,
    ...margin,
    ...margin,
    ...padding,
    ...border,
  }
}
export const container = (args: any) => {
  // console.log('Container args are: ', args)

  const { flexDirection, flexWrap, justifyContent, alignItems, width, height, minWidth, maxWidth, maxHeight, margin, padding, border } =
    args
  return {
    display: 'inline-flex !important',
    flexDirection: flexDirection || 'row',
    flexWrap: flexWrap || 'nowrap',
    justifyContent: justifyContent || 'center',
    alignItems: alignItems || 'center',
    width: width || '100%',
    minWidth: minWidth || width,
    maxWidth: maxWidth || width,
    ...margin,
    ...padding,
    ...border,
  }
}
export const element = (args: any) => {
  const { flexDirection, flexWrap, justifyContent, alignItems, width, height, minWidth, maxWidth, maxHeight, margin, padding, border } =
    args

  return {
    display: 'inline-flex !important',
    flexDirection: flexDirection || 'column',
    flexWrap: flexWrap || 'nowrap',
    justifyContent: justifyContent || 'center',
    alignItems: alignItems || 'center',
    width: width || undefined,
    height: height || 'auto',
    maxHeight: maxHeight,
    minWidth: minWidth || width,
    maxWidth: maxWidth || width,
    ...margin,
    ...padding,
    ...border,
  }
}
export const content = (args: any) => {
  const margin = args.margin ? { ...args.margin } : undefined
  const padding = args.padding ? { ...args.padding } : undefined
  const border = args.border ? { ...args.border } : undefined

  const { flexDirection, flexWrap, justifyContent, alignItems, width } = args
  return {
    display: 'inline-flex !important',
    flexDirection: flexDirection || 'row',
    flexWrap: flexWrap || 'nowrap',
    justifyContent: justifyContent || 'flex-start',
    alignItems: alignItems || 'center',
    width: width || '98%',
    ...margin,
    ...padding,
    ...border,
  }
}
export const headerDivider = (args: any) => {
  return {
    borderBottom: '0px solid !important',
  }
}
export const headerContainer = (args: any) => {
  return {
    ...css.inlineFlex,
    ...css.flexRow,
    width: args.width || '100%',
  }
}
export const headerText = (args: any) => {
  return {
    ...css.inlineFlex,
    color: args.color || pathspotPrimary,
    fontSize: args.fontSize || '2rem',
    fontWeight: 'bold',
  }
}
export const rowFieldWrapper = (args: any) => {
  return {
    ...css.inlineFlex,
    ...css.fullWidth,
    ...css.flexRow,
    ...css.justifyContentSpaceBetween,
    ...css.alignItemsBaseline,
    ...css.alignSelfCenter,
    marginBottom: '1rem',
  }
}
export const rowFieldContainer = (args: any) => {
  return {
    ...css.inlineFlex,
    ...css.flexRow,
    ...css.justifyContentStart,
    width: args.width || '100%',
    marginLeft: '1rem',
  }
}

export const iconAlignment = (args: any) => {
  const { color, fontSize, fontStyle, fontWeight, width, textAlign, margin } = args

  return {
    display: 'inline-flex !important',
    flexDirection: 'row',
    flexWrap: 'noWrap',
    color: color || pathspotPrimary,
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '50%',
  }
}
export const tooltipAlignment = (args: any) => {
  const { color, fontSize, fontStyle, fontWeight, width, textAlign, margin } = args
  return {
    display: 'inline-flex !important',
    flexDirection: 'row',
    flexWrap: 'noWrap',
    width: width || '8%',
    justifyContent: 'flex-start',
    marginLeft: '1rem',
    alignItems: 'center',
  }
}
