import React from 'react'
import { useFela } from 'react-fela'
import { container } from '../../styles/containers'
import { v4 as uuidv4 } from 'uuid'

const componentKey = `p-container-${uuidv4()}`

const PContainer = (props: any) => {
  const { flexWrap, isRow, alignItems, justifyContent, margin, border, height, width, maxWidth, minWidth, showComment, comment, name } =
    props
  if (showComment) console.log(`Props in PContainer for ${comment} are: `, props)
  const flexDirection = isRow ? 'row' : 'column'
  const { css } = useFela()
  return (
    <div
      key={`${name || uuidv4()}-${componentKey}`}
      className={css(container({ flexWrap, flexDirection, alignItems, justifyContent, margin, border, height, width, maxWidth, minWidth }))}
    >
      {props.children}
    </div>
  )
}

export default PContainer
