import React, { BaseSyntheticEvent, useEffect, useLayoutEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { useFela } from 'react-fela'
import PItemText from '../text/PItemText'
import { pathspotPrimary, pathspotWhite } from '../../../styles/ts/colors'
import PContainer from '../containers/PContainer'
import { v4 as uuidv4 } from 'uuid'
import StyledSpinner from '../../../components/spinners/StyledSpinner'
import { getUserLayoutFromAssetInfo } from '../../../../../modules/operations/locations/handwashing-goals/api/layout'

const componentUUID = uuidv4()

const checkMarginValue = 3
const checkMargin = `${checkMarginValue}px`
const checkMarginHalf = `${checkMarginValue / 2}px`

const checkbox = (args: any) => {
  const { width, height, color, cbWidth, isText } = args
  return {
    display: 'inline-flex !important',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: cbWidth || '1rem',
    height: cbWidth || 'auto',
    cursor: 'pointer',
    borderColor: pathspotPrimary,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '0.1rem',
    backgroundColor: 'transparent',
    marginLeft: isText ? '1rem' : '0',
  }
}
export const checkFill = (args: any) => {
  const { isChecked } = args
  // console.log('^^^^^^^^^^is checked is: ', isChecked)
  return {
    display: 'inline-flex !important',
    backgroundColor: isChecked ? pathspotPrimary : pathspotWhite,
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    width: `calc(100% - ${isChecked ? checkMargin : 0})`,
    height: `calc(100% - ${isChecked ? checkMargin : 0})`,
    '-webkit-transition': isChecked ? '0.4s' : 0,
    transition: isChecked ? '0.4s' : 0,
    borderColor: pathspotPrimary,
    borderWidth: isChecked ? checkMargin : 0,
    borderStyle: 'solid',
    borderRadius: '0.1rem',
    transform: `translateX(${checkMarginHalf})`,
  }
}

const PCheckBox = (props: any) => {
  const {
    callback,
    label,
    item,
    value,
    alignItems,
    justifyContent,
    width,
    fontSize: _fontSize,
    color,
    fontWeight,
    margin,
    padding,
    border,
    textAlign,
  } = props
  // console.log(`Checkbox value for ${label} is ${value}`)
  // console.log('Props for it are : ', props)
  const checkboxText = label
  const key = `${checkboxText}-${componentUUID}`
  const fontSize = _fontSize === undefined ? '0.9rem' : _fontSize
  const { css } = useFela()
  const [checkedState, setCheckedState] = useState<boolean | null>(null)
  const [valueStateChanged, setValueStateChanged] = useState<boolean>(false)

  useLayoutEffect(() => {
    // console.log('UseLayoutEffect called, checked state value is being set to: ', value)
    setCheckedState(value)
  }, [value])

  const updatedCheckedState = () => {
    if (checkedState !== null) {
      if (callback) {
        // console.log('Update checked state was clicked and state was not null################################', checkedState, item)
        callback(!checkedState, item)
      }
      // console.log(
      // 'Update checked state was clicked and state was not null################################ SEtting checked state to: ',
      //   !checkedState
      // )

      setCheckedState(!checkedState)
    }
  }
  if (checkedState === null) {
    return <></>
  }
  return (
    <PContainer isRow width={width || '100%'} justifyContent={checkboxText ? 'flex-start' : 'center'}>
      <div
        key={`${key}-div`}
        className={css(checkbox({ cbWidth: `${parseFloat(fontSize) * 1.1}rem`, isText: checkboxText }))}
        onClick={() => updatedCheckedState()}
      >
        <span key={`${key}-span`} className={css(checkFill({ isChecked: checkedState }))} />
      </div>
      {checkboxText && checkboxText !== '' && (
        <PItemText
          isRow
          margin={{ marginLeft: '0.25rem' }}
          displayText={checkboxText}
          textAlign="left"
          {...{
            alignItems: alignItems || 'center',
            justifyContent: justifyContent || 'flex-start',
            width: '75%',
            fontSize,
            color,
            fontWeight,
            padding,
            border,
          }}
        />
      )}
    </PContainer>
  )
}
export default PCheckBox

// const checkedState = useRef<boolean>(false)

// const setCheckedState = (value: boolean) => {
//   checkedState.current = value
//   setCheckedStateChanged(!checkedStateChanged)
// }
// //Set value initially from item itself
// useLayoutEffect(() => {
//   // console.log('AAAAAAAAAAAAAHHHHHHHhhhhhhhhhhhhhhh  About to take a look at the value in PCheckBox: ', value)
//   if (value !== undefined) {
//     // console.log('Setting checked state to defined value: ', value)
//     checkedState.current = value
//     // setValueStateChanged(!valueStateChanged)
//   }
// }, [value])

// //If the checked state changes, execute the callback so that is reflected in its corresponding item, or value thereof
// useEffect(() => {
//   if (checkedState.current !== null) {
//     if (checkedState.current !== null && callback) {
//       callback(checkedState.current, item)
//     }
//   }
// }, [checkedStateChanged])
// //Dummy callback, just want to change the checked state everytime the box is clicked
// const toggleCheckState = (event: BaseSyntheticEvent) => {
//   setCheckedState(!checkedState.current)
// }
// if (checkedState.current === null) {
//   return <StyledSpinner />
// }
// <span key={`${key}-span`} className={css(checkFill({ isChecked: checkedState.current }))} />
