// export const enum BaseNamespace {
//   Wrapper = 'wrapper',
//   Container = 'container',
//   Content = 'content',
//   Fill = 'fill',
//   Segment = 'segment',
//   Text = 'text',
//   Header = 'header',
//   Footer = 'footer',
//   Body = 'body',
//   Image = 'img',
//   Anchor = 'a',
//   Link = 'link',
//   Child = 'child',
//   Parent = 'parent',
//   Element = 'element',
//   Previous = 'previous',
//   Next = 'next',
//   Hover = ':hover',
//   Div = 'div',
//   Span = 'span',
//   Strong = 'strong',
//   Paragraph = 'p',
//   Stroke = 'stroke',
//   Color = 'color',
//   Size = 'size',
//   Start = 'start',
//   End = 'end',
//   First = 'first',
//   Last = 'last',
//   Percentage = 'percentage',
//   Icon = 'icon',
//   CXL = 'cxl',
//   QXL = 'qxl',
//   TXL = 'txl',
//   XXL = 'xxl',
//   XL = 'xl',
//   LG = 'lg',
//   MD = 'md',
//   SM = 'sm',
//   XS = 'xs',
//   XXS = 'xxs',
//   TXS = 'txs',
//   QXS = 'qxs',
//   CXS = 'cxs',
//   CoreUI = 'coreui',
//   Nav = 'nav',
//   Sidebar = 'sidebar',
//   Dropdown = 'dropdown',
//   Input = 'input',
//   Form = 'form',
//   Component = 'component',
//   Fragment = 'fragment',
//   Attributes = 'Attributes',
//   Group = 'group',
//   Card = 'card',
//   Column = 'Column',
//   Row = 'row',
//   Flex = 'flex',
//   Grid = 'grid',
//   Background = 'background',
//   Foreground = 'foreground',
//   Filter = 'filter',
//   Bar = 'bar',
//   Brand = 'brand',
//   Override = 'override',
//   Default = 'default',
//   Base = 'base',
//   Extend = 'extend',
//   KeyFrame = 'keyframe',
//   Variable = 'var',
//   Constant = 'const',
//   SVG = 'svg',
//   Root = 'root',
//   Context = 'context',
//   Pulse = 'pulse',
//   Spin = 'spin',
//   Fade = 'fade',
//   Function = 'function',
//   Parameter = 'parameter',
//   Block = 'block',
//   Inline = 'inline',
//   Inner = 'inner',
//   Sub = '<',
//   Mixin = 'mixin',
//   Fixed = 'fixed',
//   Dynamic = 'dynamic',
//   Offset = 'offset',
//   Rule = 'rule',
//   Contrast = 'contrast',
//   Graphic = 'graphic',
//   Shell = 'shell',
//   Important = 'important',
//   LTR = 'left-to-right',
//   RTL = 'right-to-left',
//   Before = ':before',
//   After = ':after',
//   Basic = 'basic',
//   Advanced = 'advanced',
//   Transition = 'transition',
//   Animation = 'animation',
//   Duration = 'duration',
//   Process = 'process',
//   Detail = 'detail',
//   Collapse = 'collapse',
//   Hidden = 'hidden',
//   Display = 'display',
//   Print = 'print',
//   PDF = 'pdf',
//   Inherit = 'inherit',
//   Auto = 'auto',
//   Baseline = 'baseline',
//   Center = 'center',
//   Left = 'left',
//   Right = 'right',
//   Top = 'top',
//   Bottom = 'bottom',
//   Absolute = 'absolute',
//   Psuedo = 'psuedo',
//   Selector = 'selector',
//   List = 'list',
//   Array = 'array',
//   Number = 'number',
//   String = 'string',
//   Object = 'object',
//   Import = 'import',
//   Export = 'export',
//   Save = 'save',
//   Load = 'load',
//   Button = 'button',
//   Section = 'section',
//   Expandable = 'expandable',
//   Direction = 'direction',
//   BaseClass = 'baseclass',

import _ from 'lodash'

// }
export const basenamespace = {
  wrapper: 'wrapper',
  container: 'container',
  pcontainer: 'pcontainer',
  content: 'content',
  fill: 'fill',
  segment: 'segment',
  text: 'text',
  header: 'header',
  footer: 'footer',
  body: 'body',
  image: 'img',
  anchor: 'a',
  link: 'link',
  child: 'child',
  parent: 'parent',
  element: 'element',
  previous: 'previous',
  next: 'next',
  hover: ':hover',
  div: 'div',
  span: 'span',
  strong: 'strong',
  paragraph: 'p',
  stroke: 'stroke',
  color: 'color',
  size: 'size',
  start: 'start',
  end: 'end',
  first: 'first',
  last: 'last',
  percentage: 'percentage',
  icon: 'icon',
  cxl: 'cxl',
  qxl: 'qxl',
  txl: 'txl',
  xxl: 'xxl',
  xl: 'xl',
  lg: 'lg',
  md: 'md',
  sm: 'sm',
  xs: 'xs',
  xxs: 'xxs',
  txs: 'txs',
  qxs: 'qxs',
  cxs: 'cxs',
  coreui: 'coreui',
  nav: 'nav',
  sidebar: 'sidebar',
  dropdown: 'dropdown',
  input: 'input',
  form: 'form',
  component: 'component',
  fragment: 'fragment',
  attributes: 'attributes',
  group: 'group',
  card: 'card',
  column: 'column',
  row: 'row',
  flex: 'flex',
  grid: 'grid',
  background: 'background',
  foreground: 'foreground',
  filter: 'filter',
  bar: 'bar',
  brand: 'brand',
  override: 'override',
  default: 'default',
  base: 'base',
  extend: 'extend',
  keyframe: 'keyframe',
  variable: 'var',
  constant: 'const',
  svg: 'svg',
  root: 'root',
  context: 'context',
  pulse: 'pulse',
  spin: 'spin',
  fade: 'fade',
  function: 'function',
  parameter: 'parameter',
  block: 'block',
  inline: 'inline',
  inner: 'inner',
  sub: '<',
  mixin: 'mixin',
  fixed: 'fixed',
  dynamic: 'dynamic',
  offset: 'offset',
  rule: 'rule',
  contrast: 'contrast',
  graphic: 'graphic',
  shell: 'shell',
  important: 'important',
  ltr: 'left-to-right',
  rtl: 'right-to-left',
  before: ':before',
  after: ':after',
  basic: 'basic',
  advanced: 'advanced',
  transition: 'transition',
  animation: 'animation',
  duration: 'duration',
  process: 'process',
  detail: 'detail',
  collapse: 'collapse',
  hidden: 'hidden',
  display: 'display',
  print: 'print',
  pdf: 'pdf',
  inherit: 'inherit',
  auto: 'auto',
  baseline: 'baseline',
  center: 'center',
  left: 'left',
  right: 'right',
  top: 'top',
  bottom: 'bottom',
  absolute: 'absolute',
  psuedo: 'psuedo',
  selector: 'selector',
  list: 'list',
  array: 'array',
  number: 'number',
  string: 'string',
  object: 'object',
  import: 'import',
  export: 'export',
  save: 'save',
  load: 'load',
  button: 'button',
  section: 'section',
  expandable: 'expandable',
  direction: 'direction',
  baseclass: 'baseclass',
}

export type BaseSelectorType = typeof basenamespace
