import * as React from 'react'
import { pathspotPrimary } from '../../../../ts/colors'

const PathSpotIncrementRaw = (props: any) => {
  const { fillColor, strokeColor, checked, className, viewBox, width, height } = props
  return (
    <svg viewBox={viewBox || '0 0 341.356 512.053'} className={className || 'p-icon-5xl'}>
      <path
        d="M.526 25.222V486.83a24.694 24.694 0 0040.044 19.346l290.913-230.804a24.696 24.696 0 000-38.692L40.57 5.876a24.694 24.694 0 00-26.091-2.89A24.694 24.694 0 00.526 25.222zm49.391 410.492V76.339l226.484 179.688L49.917 435.714z"
        fill={fillColor || pathspotPrimary}
        stroke={strokeColor || pathspotPrimary}
        strokeMiterlimit={10}
        strokeWidth={1.053}
      />
    </svg>
  )
}

const PathSpotIncrement = React.memo(PathSpotIncrementRaw)
export default PathSpotIncrement
