import React from 'react'
import { useFela } from 'react-fela'
import PContentRow from '../rows/PContentRow'

import { v4 as uuidv4 } from 'uuid'
import PIcon from '../../../components/icons/PIcon/PIcon'
import PContent from '../containers/PContent'
import { debugBorder } from '../../styles/containers'
import PElement from '../containers/PElement'
import PIconButton from './PIconButton'
import PContainer from '../containers/PContainer'
const componentKey = uuidv4()

const PButtonRow = (props: any) => {
  const { buttons: _buttons, width, alignItems, justifyContent, margin, showComment, callbacks } = props
  const { css } = useFela()
  const buttons = _buttons ? (!Array.isArray(_buttons) ? [{ ..._buttons }] : [..._buttons]) : []
  if (showComment) console.log('Buttons are: ', buttons)
  return buttons ? (
    <PContainer
      isRow
      maxWidth={width || '35%'}
      alignItems={alignItems || 'center'}
      justifyContent={justifyContent || 'space-between'}
      margin={margin || { marginBottom: '1rem', marginTop: '1rem' }}
    >
      {buttons.map((button: any, idx: number) => {
        const { margin: buttonMargin, padding: buttonPadding } = button

        return (
          <PIconButton
            key={`${componentKey}-brow-${button.text}-button`}
            justifyContent={justifyContent}
            alignItems={alignItems}
            button={button}
            margin={{ ...buttonMargin }}
            padding={{ ...buttonPadding }}
            onClick={
              callbacks && callbacks[idx]
                ? callbacks[idx]
                : button && button.onClick
                ? button.onClick
                : () => {
                    console.log('Callback not implemented for this button.')
                  }
            }
          />
        )
      })}
    </PContainer>
  ) : (
    <></>
  )
}

export default PButtonRow

// import React from 'react'
// import './buttons.scss'

// const PWrapperButton = (props: any) => {
//   let {
//     className,
//     onClick,
//     disabled,
//     active,
//     block,
//     color,
//     size,
//     pressed,
//     shape,
//     variant,
//     buttonType,
//     svgComponent: SVGComponent,
//     children,
//     ...attributes
//   } = props

//   const click = (e: any) => !disabled && onClick && onClick(e)

//   return (
//     <button className={className || 'btn-clear'} type={buttonType || 'button'} disabled={disabled} {...attributes} onClick={click}>
//       {children}
//     </button>
//   )
// }

// export default PWrapperButton
