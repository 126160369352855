import React from 'react'
import { useFela } from 'react-fela'
import { pathspotPrimary, toastifyError, toastifySuccess, toastifyWarning } from '../../../../../webapp-lib/pathspot-react/styles/ts/colors'

const tableRowContainer = (args: any) => {
  return {
    display: 'flex !important',
    flexDirection: 'row',
    width: '100%',
    borderColor: toastifyWarning,
    borderStyle: 'solid',
    borderWidth: '0px',
    marginTop: '0.5rem',
    marginBottome: '0.5rem',
  }
}
const PTableRow = (props: any) => {
  const { isHeader, rowIdx, label } = props
  const { css } = useFela()
  return (
    <div key={`${label}-table-column-${isHeader ? 'header' : rowIdx}`} className={css(tableRowContainer)}>
      {props.children}
    </div>
  )
}

export default PTableRow
