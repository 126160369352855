import React, { BaseSyntheticEvent, useEffect, useLayoutEffect, useRef, useState } from 'react'
import '../../styles/scss/clickable.scss'
import _ from 'lodash'
import StyledSpinner from '../spinners/StyledSpinner'

type PathspotSliderProps = {
  onClick: () => void
}

export const PSliderToggle = (props: any) => {
  const { onClick, sliderTextOn, sliderTextOff, value } = props
  // const [checkedState, setCheckedState] = useState<boolean | null>(null)

  // console.log('Props in p slider toggle are: ', props)

  const checkedState = useRef<boolean>(false)
  const [checkedStateChanged, setCheckedStateChanged] = useState<boolean | null>(null)
  const [sliderText, setSliderText] = useState<undefined | string>(sliderTextOff)

  const setCheckedState = (value: boolean) => {
    checkedState.current = value
    setCheckedStateChanged(!checkedStateChanged)
  }

  //Set value initially from item itself
  useLayoutEffect(() => {
    setCheckedState(value)
  }, [value])

  //If the checked state changes, execute the callback so that is reflected in its corresponding item, or value thereof
  useEffect(() => {
    if (checkedState.current !== null) {
      if (onClick) {
        onClick(checkedState.current)
      }
      setSliderText(checkedState.current ? sliderTextOn : sliderTextOff)
    }
  }, [checkedStateChanged])

  //Dummy callback, just want to change the checked state everytime the box is clicked
  const toggleCheckState = (event: BaseSyntheticEvent) => {
    setCheckedState(!checkedState.current)
  }
  if (checkedState.current === null) {
    return <StyledSpinner />
  }
  return (
    <label className="switch">
      <input type="checkbox" onChange={toggleCheckState} checked={checkedState.current} />
      <span className="slider" />
      {sliderText && <strong className="slider-text">{sliderText}</strong>}
    </label>
  )
}
export default PSliderToggle
PSliderToggle.displayName = 'PSliderToggle'

// useLayoutEffect(() => {
//   console.log('Value in PSliderToggle is: ', value)
//   if (checkedState === null) {
//     console.log('Made it to the null', props)
//     setCheckedState(value === false ? false : value === true ? true : false)
//   }
// }, [])

// useEffect(() => {
//   if (checkedState !== null) {
//     onClick(checkedState)
//     setSliderText(checkedState ? sliderTextOn : sliderTextOff)
//   }
// }, [checkedState])
// const toggleSwitch = (checkVal: any) => {
//     // console.log("I'm gonna toggle that switch. Passed arg is: ", checkVal)
//     const isCheckedValue = _.cloneDeep(checkedState)
//     if (isCheckedValue === null) {
//       // console.log('Toggling that sum bitch from null.')
//       setCheckedState(true)
//       // onClick(true)
//     } else {
//       // console.log('Toggle that skank from boolean value: ', checkedState)
//       setCheckedState(!isCheckedValue)
//       // onClick(!isCheckedValue)
//     }
//   }
// console.log('I get rendered but never seem to be able to change. Slider text is: ', sliderText)
