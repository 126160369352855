import React from 'react';
import {CCardText, CCardTitle} from '@coreui/react';

const CardMessage = (props: any) => {
  const {messageTitle, messageText, title, message} = props;
  const itemsToRender = [];
  const renderMessage = messageText ? messageText : message;
  const renderTitle = messageTitle ? messageTitle : title;
  if (renderTitle) {
    itemsToRender.push(<CCardTitle className="mb-3">{renderTitle}</CCardTitle>);
  }
  if (renderMessage) {
    itemsToRender.push(<CCardText className="mb-3">{renderMessage}</CCardText>);
  }
  return <span>{itemsToRender}</span>;
};
export default CardMessage;
