import { api } from '../../api/api'
import { API_URL_GET_FILTERS_FOR_COMP_DASHBOARD, API_URL_GET_FILTERS_FOR_HANDWASHES_DASHBOARD } from '../../api/constants'
import moment, { Moment } from 'moment'
import {
  FilterDisplayConfig,
  ContextFilterState,
  FilterBarComponentsState,
  FiltersBarState,
  FilteredDashboards,
  FilterBarListComponentsState,
  FiltersStateListTarget,
  FilterBarComponent,
} from './filters-bar.types'
import _ from 'lodash'
import { mapValueFromKeyMatch } from '../../webapp-lib/pathspot-react'
export const defaultDisplayState: FilterDisplayConfig = {
  dateRange: false,
  locationGroups: false,
  locations: false,
  stations: false,
  employees: false,
  departments: false,
}
export const emptyFilterComponentsState = {
  dateRange: { start: null, end: null, delta: null },
  locationGroups: [],
  locations: [],
  stations: [],
  employees: [],
  departments: [],
}
export const emptyContextFilterState: ContextFilterState = {
  context: null,
  selections: { ...emptyFilterComponentsState },
  options: { ...emptyFilterComponentsState },
  display: { ...defaultDisplayState },
  privileges: { ...emptyFilterComponentsState },
}
export const getAllLocationsAndLocationGroups = async (): Promise<any> => {
  return await api.withAuth().url(`${API_URL_GET_FILTERS_FOR_COMP_DASHBOARD}`).get().json().then(api.zjson)
}
export const getRandomReqId = () => {
  return Math.floor(Math.random() * 10000)
}
export const getFiltersForHandwashesPage = async (): Promise<any> => {
  return await api.withAuth().url(`${API_URL_GET_FILTERS_FOR_HANDWASHES_DASHBOARD}`).get().json().then(api.zjson)
}
export const formatFiltersForDataRequest = (filters: FilterBarComponentsState) => {
  const noDates = filters.dateRange?.start === undefined && filters.dateRange?.end === undefined
  const formattedFilters = {
    selectedDateRange: noDates
      ? undefined
      : {
          startDate: filters.dateRange.start,
          endDate: filters.dateRange.end,
        },
    selectedLocationGroups: filters.locationGroups.length ? filters.locationGroups : undefined,
    selectedLocations: filters.locations.length ? filters.locations : undefined,
    selectedDepartments: filters.departments.length ? filters.departments : undefined,
    selectedStations: filters.stations.length ? filters.stations : undefined,
    selectedEmployees: filters.employees.length ? filters.employees : undefined,
  }
  //console.log('Formatted filters are: ', formattedFilters)
  return formattedFilters
}
export const filterMomentToString = (filterMoment: Moment) => `${filterMoment.format('LLL')}`
export const isEqualFilterSelections = (currentSelections: any, previousSelections: any) => {
  const boolState = Object.keys(currentSelections).map((key: string) => {
    if (key === 'dateRange' || key === 'allowedDateRange') {
      const previousStart =
        previousSelections[key].start !== null ? filterMomentToString(previousSelections[key].start) : previousSelections[key].start
      const currentStart =
        currentSelections[key].start !== null ? filterMomentToString(currentSelections[key].start) : currentSelections[key].start
      const previousEnd =
        previousSelections[key].end !== null ? filterMomentToString(previousSelections[key].end) : previousSelections[key].end
      const currentEnd = currentSelections[key].end !== null ? filterMomentToString(currentSelections[key].end) : currentSelections[key].end
      const startEqual = previousStart === currentStart
      const endEqual = previousEnd === currentEnd
      //console.log('Filter selection comparison: ', previousStart, currentStart, previousEnd, currentEnd, startEqual, endEqual)
      return startEqual && endEqual
    } else if (key === 'reqId') {
      return true
    } else {
      try {
        if (currentSelections[key] !== null && previousSelections[key] !== null) {
          let contentMatch = false
          const sizeMatch =
            previousSelections[key].length > 0 &&
            currentSelections[key].length > 0 &&
            previousSelections[key].length === currentSelections[key].length
          if (sizeMatch) {
            contentMatch = previousSelections[key].every((item: any) =>
              currentSelections[key].map((currentItem: any) => currentItem.label).includes(item.label)
            )
            return contentMatch
          } else if (previousSelections[key].length === 0 && currentSelections[key].length === 0) {
            return true
          } else {
            return false
          }
        }
        return false
      } catch (e) {}
    }
  })
  //console.log('Current, previous filter states are: ', currentSelections, previousSelections)
  //console.log('These filters are considered equal: ', !boolState.includes(false))
  return !boolState.includes(false)
}
//This function checks both the context and the filter substate category (selctions, options, etc.)
export const isEmptyContextFilterSubState = (filtersState: ContextFilterState, subStateTarget: FiltersStateListTarget) => {
  const hasContext = filtersState.context !== null
  if (!isEmptyFilterComponentsState(filtersState[subStateTarget])) {
    return false
  }
  if (hasContext) {
    return false
  }
  return true
}
export const isUndefinedFiltersDisplayState = (filtersObj: FilterDisplayConfig | undefined) => {
  if (filtersObj === undefined) {
    return undefined
  }
  const boolState = Object.keys(filtersObj).map((key: string) => {
    try {
      return filtersObj[key] === null || filtersObj[key] === false ? true : false
    } catch (e) {
      return true
    }
  })
  return !boolState.includes(false)
}

export const isEmptyFilterComponentsState = (filtersObj: FilterBarComponentsState | undefined) => {
  if (filtersObj === undefined) {
    return undefined
  }
  const boolState = Object.keys(filtersObj).map((key: string) => {
    if (key === 'dateRange') {
      return filtersObj.dateRange.start === null && filtersObj.dateRange.end === null
    } else if (key === 'reqId') {
      return false
    } else {
      try {
        if (filtersObj[key] === null) {
          return true
        }
        return (filtersObj[key] as Array<any>).length === 0 ? true : false
      } catch (e) {
        return true
        //console.log('Error in checking empty filter state!!!!!!!!!!!!!!!!')
      }
    }
  })
  //console.log('Bool state in is empty function is: ', [boolState, boolState.includes(false)]);
  return !boolState.includes(false)
}
export const updateFilterState = (globalFilterSelections: any, queryFilterState: any, defaultFilterState: any) => {
  if (isEmptyFilterComponentsState(queryFilterState)) {
    if (isEmptyFilterComponentsState(globalFilterSelections)) {
      // console.log('---------BBBB-1--------- Global state is also empty, returning default filter state: ', defaultFilterState)
      return defaultFilterState
    } else {
      // console.log('----@-----BBBB-2-----@---- Global filter state is not empty, returning it: ', globalFilterSelections)
      return globalFilterSelections
    }
  } else {
    // console.log('--------BBBB-3---------  Query params not empty, returning query filter state: ', queryFilterState)
    return queryFilterState
  }
  //Only want to carry filter state over if its been changed.
  // return globalFilterState.touched ? globalFilterState.selections : defaultFilterState
}
export const getFilterSelectionsFromURL = (queryParams: any) => {
  //console.log('****-aaaa-**** getFiltersOjectFromURL called...')

  //console.log('444.3 getFiltersObjectFromURL: queryParams ', queryParams)
  const locationGroupIds = 'locationGroupId' in queryParams ? [queryParams.locationGroupId] : []
  const locationIds = 'locationId' in queryParams ? [queryParams.locationId] : []
  const departmentIds = 'departmentId' in queryParams ? [queryParams.departmentId] : []
  const stationIds = 'stationId' in queryParams ? [queryParams.stationId] : []
  const employeeIds = 'employeeId' in queryParams ? [queryParams.employeeId] : []
}
export const updateDisplayNames = (filtersAffData: any) => {
  //console.log("Filters affdata before relabel is: ", filtersAffData);

  //This only run once so names do not constantly append parenthetical items on re-render
  const { locationGroups, locations, stations, employees, departments } = filtersAffData
  //console.log("Filters aff data is: ", filtersAffData);
  //console.log("Departments fetched are: ", departments);
  if (departments && departments.length > 0) {
    departments.forEach((element: any) => {
      let parentLocation = locations.find((loc: any) => loc.value === element._affLocationId)
      element.label = parentLocation ? `${element.label}  (${parentLocation.label})` : element.label
    })
  }
  if (stations && stations.length > 0) {
    stations.forEach((stationItem: any) => {
      let parentLocation = locations.find((loc: any) => loc._affStationIds.includes(stationItem.value))
      stationItem.label = parentLocation ? `${stationItem.label} (${parentLocation.label})` : stationItem.label
    })
  }
  //console.log("Filters affdata after relabel is: ", filtersAffData);
  return filtersAffData
}
export const getPrivilegedItemsFromIdList = (objectKey: string, idList: any[], contextFilterPrivileges: FilterBarComponentsState) => {
  const { context, ...filterLists } = contextFilterPrivileges
  const filterPrivileges = { ...filterLists } as FilterBarListComponentsState
  let privilegedItems = []
  if (idList.length > 0) {
    privilegedItems = filterPrivileges[objectKey].filter((item: any) => idList.some((id: any) => item.value === parseInt(id)))
  }
  return privilegedItems
}
export const getFiltersOjectFromURL = (queryParams: any, contextPrivileges: any): FilterBarComponentsState => {
  const queryIdLists: FilterBarListComponentsState = {
    locationGroups: 'locationGroupId' in queryParams ? [queryParams.locationGroupId] : [],
    locations: 'locationId' in queryParams ? [queryParams.locationId] : [],
    departments: 'departmentId' in queryParams ? [queryParams.departmentId] : [],
    stations: 'stationId' in queryParams ? [queryParams.stationId] : [],
    employees: 'employeeId' in queryParams ? [queryParams.employeeId] : [],
  }
  const filterComponentValues = Object.keys(queryIdLists).map((itemKey: any) => {
    return [itemKey, getPrivilegedItemsFromIdList(itemKey, queryIdLists[itemKey], contextPrivileges)]
  })
  const filterItemLists: FilterBarListComponentsState = Object.fromEntries(filterComponentValues)
  const start: any = 'starDate' in queryParams ? moment(queryParams.start, 'YYYY-MM-DD') : null
  const end: any = 'endDate' in queryParams ? moment(queryParams.end, 'YYYY-MM-DD') : null
  const delta = end !== null && start !== null ? end - start : null
  const filtersObj: FilterBarComponentsState = {
    ...filterItemLists,
    dateRange: {
      start: start,
      end: end,
      delta: delta,
    },
  }
  return filtersObj
}
export const getFiltersStateOptions = {
  locationGroups: (filtersState: ContextFilterState) => {
    //if no LG is selected, then return all available location items
    const selectedLGs = filtersState.selections.locationGroups
    return selectedLGs
      ? filtersState.privileges.locationGroups.filter((x: any) => !selectedLGs?.includes(x.value))
      : filtersState.privileges.locationGroups
  },
  locations: (filtersState: ContextFilterState) => {
    //if no LG is selected, then return all available location items
    const selectedLGs = filtersState.selections.locationGroups
    if (!selectedLGs || !selectedLGs.length) {
      return filtersState.privileges.locations
    }
    //if there are any LGs selected, we only need locations that are affiliated with them
    let allowedLocationIds: any = []
    selectedLGs.forEach((item: any) => {
      allowedLocationIds = [...allowedLocationIds, ...item._affLocationIds]
    })
    return filtersState.privileges.locations.filter((x: any) => allowedLocationIds.includes(x.value))
  },
  departments: (filtersState: ContextFilterState) => {
    const selectedLGs = filtersState.selections.locationGroups
    const selectedLocs = filtersState.selections.locations
    //if no LG or LOC is selected, then return all available departments items
    if ((!selectedLGs || !selectedLGs.length) && (!selectedLocs || !selectedLocs.length)) {
      return filtersState.privileges.departments
    }
    //if there are any LGs or LOCs selected, we only need departments that are affiliated with them
    const locsToTraverse = selectedLocs && selectedLocs.length ? selectedLocs : getFiltersStateOptions.locations(filtersState)
    let allowedDepartments: any = []
    filtersState.privileges.departments.forEach((dept: any) => {
      let affiliatedLocation = dept._affLocationId
      allowedDepartments = locsToTraverse.find((loc: any) => loc.value === affiliatedLocation)
        ? [...allowedDepartments, dept]
        : allowedDepartments
    })
    return filtersState.privileges.departments.filter((x: any) => allowedDepartments.includes(x.value))
  },
  stations: (filtersState: ContextFilterState) => {
    const selectedLGs = filtersState.selections.locationGroups
    const selectedLocs = filtersState.selections.locations
    const selectedDepts = filtersState.selections.departments

    //if no LG or LOC is selected, then return all available station items
    if ((!selectedLGs || !selectedLGs.length) && (!selectedLocs || !selectedLocs.length) && (!selectedDepts || !selectedDepts.length)) {
      return filtersState.privileges.stations
    }

    let allowedStationIds: any = []

    if (!selectedDepts || !selectedDepts.length) {
      //if there are any LGs or LOCs selected and we are not using departments, we only need stations that are affiliated with the locations
      const locsToTraverse = selectedLocs && selectedLocs.length ? selectedLocs : getFiltersStateOptions.locations(filtersState)
      locsToTraverse.forEach((item: any) => {
        allowedStationIds = item?._affStationIds ? [...allowedStationIds, ...item._affStationIds] : [...allowedStationIds]
      })
    } else {
      const deptsToTraverse = selectedDepts && selectedDepts.length ? selectedDepts : getFiltersStateOptions.departments(filtersState)
      deptsToTraverse.forEach((item: any) => {
        allowedStationIds = [...allowedStationIds, ...item._affStationIds]
      })
    }
    return filtersState.privileges.stations.filter((x: any) => allowedStationIds.includes(x.value))
  },
  employees: (filtersState: ContextFilterState) => {
    const selectedLGs = filtersState.selections.locationGroups
    const selectedLocs = filtersState.selections.locations
    //if no LG or LOC is selected, then return all available employee items
    if ((!selectedLGs || !selectedLGs.length) && (!selectedLocs || !selectedLocs.length)) {
      return filtersState.privileges.employees
    }
    //if there are any LGs or LOCs selected, we only need employees that are affiliated with them
    const locsToTraverse = selectedLocs && selectedLocs.length ? selectedLocs : getFiltersStateOptions.locations(filtersState)
    let allowedEmployeeIds: any = []
    locsToTraverse.forEach((item: any) => {
      allowedEmployeeIds = [...allowedEmployeeIds, ...item._affEmployeeIds]
    })
    return filtersState.privileges.employees.filter((x: any) => allowedEmployeeIds.includes(x.value))
  },
}
export const updateFiltersStateSelections = {
  locationGroups: (newFiltersState: ContextFilterState) => {
    newFiltersState.options = { ...newFiltersState.options, locationGroups: getFiltersStateOptions.locationGroups(newFiltersState) }
    newFiltersState.selections = {
      ...newFiltersState.selections,
      locationGroups: newFiltersState.selections.locationGroups?.filter((x: any) =>
        newFiltersState.options.locationGroups.some((el: any) => el.value === x.value)
      ),
    }
  },
  locations: (newFiltersState: ContextFilterState) => {
    if (newFiltersState.display.locations) {
      newFiltersState.options = { ...newFiltersState.options, locations: getFiltersStateOptions.locations(newFiltersState) }
      newFiltersState.selections = {
        ...newFiltersState.selections,
        locations: newFiltersState.selections.locations?.filter((x: any) =>
          newFiltersState.options.locations?.some((el: any) => el.value === x.value)
        ),
      }
    }
  },
  departments: (newFiltersState: ContextFilterState) => {
    if (newFiltersState.display.departments) {
      newFiltersState.options = { ...newFiltersState.options, departments: getFiltersStateOptions.departments(newFiltersState) }
      newFiltersState.selections = {
        ...newFiltersState.selections,
        departments: newFiltersState.selections.departments?.filter((x: any) =>
          newFiltersState.options.departments?.some((el: any) => el.value === x.value)
        ),
      }
    }
  },
  stations: (newFiltersState: ContextFilterState) => {
    if (newFiltersState.display.stations) {
      newFiltersState.options = { ...newFiltersState.options, stations: getFiltersStateOptions.stations(newFiltersState) }
      newFiltersState.selections = {
        ...newFiltersState.selections,
        stations: newFiltersState.selections.stations.filter((x: any) =>
          newFiltersState.options.stations.some((el: any) => el.value === x.value)
        ),
      }
    }
  },
  employees: (newFiltersState: ContextFilterState) => {
    if (newFiltersState.display.employees) {
      newFiltersState.options = { ...newFiltersState.options, employees: getFiltersStateOptions.employees(newFiltersState) }
      newFiltersState.selections = {
        ...newFiltersState.selections,
        employees: newFiltersState.selections.employees.filter((x: any) =>
          newFiltersState.options.employees.some((el: any) => el.value === x.value)
        ),
      }
    }
  },
}
const setContextFilterFromResponse = (
  currentFilterBarState: FiltersBarState,
  filterContext: FilteredDashboards,
  privilegeResponseObj: any
): FiltersBarState => {
  //console.log('Filter state before using response data is: ', _.cloneDeep(currentFilterBarState))

  if (currentFilterBarState && currentFilterBarState.contextFiltersStates) {
    //first update display names
    const privilegeData = updateDisplayNames(privilegeResponseObj.data)
    const displayPrivileges = privilegeResponseObj.config
    //console.log('Display privelages are : ', displayPrivileges)
    const currentCachedFilterState = getCachedFilterState(filterContext, currentFilterBarState.contextFiltersStates)
    //console.log('Current cached filter state in **************', currentCachedFilterState)
    const otherContextFilterStates = currentFilterBarState.contextFiltersStates.filter((filterObj: ContextFilterState) => {
      return filterObj.context !== filterContext
    })
    const mappedDisplayPrivelages = mapValueFromKeyMatch(currentCachedFilterState?.display, displayPrivileges)
    //console.log('Mapped display privileges are: ', mappedDisplayPrivelages)
    //console.log('Other contexts in filter bar state are: ', otherContextFilterStates)
    if (currentCachedFilterState) {
      currentFilterBarState = {
        ...currentFilterBarState,
        contextFiltersStates: [
          ...otherContextFilterStates,
          {
            ...currentCachedFilterState,
            privileges: privilegeData,
            display: mappedDisplayPrivelages,
          },
        ],
      }
    }
    //If the current state matches the page context or is null, clone privileges into current state as well
    if (currentFilterBarState.filtersState.context === filterContext || currentFilterBarState.filtersState.context === null) {
      currentFilterBarState = {
        ...currentFilterBarState,
        filtersState: {
          ...currentFilterBarState.filtersState,
          context: filterContext, //in case it is null
          privileges: { ...privilegeData },
          display: mappedDisplayPrivelages,
        },
      }
    }
  }
  //console.log('Filter bar state after using response data is: ', _.cloneDeep(currentFilterBarState))

  return currentFilterBarState
}
const getCachedFilterState = (context: FilteredDashboards, contextFilters: ContextFilterState[]) => {
  //console.log('Context filters are: ', contextFilters)
  const cachedFilterState = contextFilters.filter((filtersObj: any) => {
    //console.log(`Comparing ${context} using filters object: `, filtersObj)
    return filtersObj.context === context
  })
  //console.log('Cached filter state filtered from list is: ', cachedFilterState)
  if (cachedFilterState) {
    return cachedFilterState[0]
  } else {
    //console.log(`Couldn't find filter context: ${context}`)
    return undefined
  }
}
const setCachedFilterState = (currentState: FiltersBarState, context: FilteredDashboards, contextFilterState: ContextFilterState) => {
  if (currentState && currentState.contextFiltersStates) {
    const otherContextFilterStates = currentState.contextFiltersStates.filter((filterObj: ContextFilterState) => {
      return filterObj.context !== context
    })

    const updatedFilterBarState = {
      ...currentState,
      contextFiltersStates: [
        ...otherContextFilterStates,
        {
          ...contextFilterState,
        },
      ],
    }
    //console.log('Updated Filter Bar state in set cachedFilterState is: ', _.cloneDeep(updatedFilterBarState))
    return { ...updatedFilterBarState }
  } else {
    console.warn('Current state in setCachedFilterState is ill-defined: ', _.cloneDeep(currentState))
    return currentState
  }
}
const setCurrentFilterState = (currentFilterBarState: FiltersBarState, filtersState: ContextFilterState): FiltersBarState => {
  if (filtersState && filtersState.context && filtersState.privileges) {
    currentFilterBarState = { ...currentFilterBarState, filtersState: { ...filtersState } }
  } else {
    console.warn(
      'Current filters state will be set with invalid filters object (missing privileges or context): ',
      _.cloneDeep(filtersState)
    )
  }
  return currentFilterBarState
}

const setCurrentFilterStateFromCache = (currentFilterBarState: FiltersBarState, filterContext: FilteredDashboards): FiltersBarState => {
  if (currentFilterBarState && currentFilterBarState.contextFiltersStates) {
    const cachedContextFilterState = getCachedFilterState(filterContext, currentFilterBarState.contextFiltersStates)
    if (cachedContextFilterState) {
      currentFilterBarState = setCurrentFilterState(currentFilterBarState, { ...cachedContextFilterState })
    }
  }
  return currentFilterBarState
}
const mergeCurrentStateToPageDefault = async (
  currentState: ContextFilterState,
  defaultFilterState: FilterBarComponentsState,
  responseData: any
) => {}
const getNonEmptyFilterComponents = (filterComponents: FilterBarComponentsState) => {}

//This function reduces the filter state to the union of the desired filter state and what is allowed. It uses sourcery to acheive that.
const reduceFiltersStateFromPrivileges = (filtersState: ContextFilterState) => {
  //Pathspot school of witchcraft and wizardry
  //updateFiltersStateSelections is a javascript object wherein each attribute/property is the updater function for the respective attribute key, and these keys match the filter components (locations, stattions, etc.)
  const reducedFilterState = Object.entries(updateFiltersStateSelections).reduce(
    (updatedState: ContextFilterState, [key, keyFunction]: any) => {
      keyFunction(updatedState)
      return updatedState
    },
    filtersState
  )

  return reducedFilterState
}

const applyNewFiltersState = (
  privileges: FilterBarComponentsState,
  displayConfig: FilterDisplayConfig,
  filtersState: ContextFilterState
) => {
  //console.log('In applyNewFiltersState, privileges are: ', privileges)
  //console.log('In applyNewFiltersState, displayConfig are: ', displayConfig)
  //console.log('In applyNewFiltersState, filtersState are: ', filtersState)

  const targetFiltersState = { ...filtersState, privileges, display: { ...displayConfig } }
  //console.log('In applyNewFiltersState, targetFiltersState is: ', targetFiltersState)
  const updatedTargetFiltersState = reduceFiltersStateFromPrivileges(targetFiltersState)

  //console.log('Updated target filters state from getPermitted filters state is: ', _.cloneDeep(updatedTargetFiltersState))
  return { ...updatedTargetFiltersState }
}
const coalesceEmptyFilterComponentsAFromB = (
  filterComponentsStateA: FilterBarComponentsState,
  filterComponentsStateB: FilterBarComponentsState
) => {
  //console.log('In coalesce empty filter components from A to B, FilterComponentState A is: ', filterComponentsStateA)
  //console.log('In coalesce empty filter components from A to B, FilterComponentState B is: ', filterComponentsStateB)
  //console.log('In coalesce empty filter components from A to B, FilterComponentState entries are ', Object.entries(filterComponentsStateA))

  const coalescedFilterComponents = Object.entries(filterComponentsStateA).map(([key, value]: any) => {
    if (key === 'dateRange') {
      if (value.start === null && value.end === null) {
        const returnValNullDate = filterComponentsStateB[key]
        return [key, returnValNullDate]
      } else {
        const returnValNonNullDate = filterComponentsStateA[key]
        return [key, returnValNonNullDate]
      }
    } else {
      const filterComponentALists = filterComponentsStateA as FilterBarListComponentsState
      if (filterComponentALists && filterComponentALists[key] && filterComponentALists[key].length === 0) {
        const returnListEmptyKey = filterComponentsStateB[key]
        return [key, returnListEmptyKey]
      } else {
        const returnListOriginal = filterComponentsStateA[key]
        return [key, returnListOriginal]
      }
    }
  })

  //console.log('Coalesced Filter Components Array A from B are: ', coalescedFilterComponents)
  const returnFilterComponentsState: FilterBarComponentsState = Object.fromEntries(coalescedFilterComponents)
  //console.log('Coalesced Filter Components A from B are:', returnFilterComponentsState)
  return returnFilterComponentsState
}
//This function only uses the default filter state and the cached filter state privileges to coalesce the filter state.
//In other words, the current filter state is ignored and so are any selections on the cached filter state.
//This is useful for absolute filter state setting, such as that from a query string.
const coalesceBlindFilterState = (
  currentState: FiltersBarState,
  context: FilteredDashboards,
  filtersState: ContextFilterState,
  defaultFilterComponentsState: FilterBarComponentsState
) => {
  let updatedFilterBarState = { ...currentState }
  const cachedFilterState = getCachedFilterState(context, updatedFilterBarState.contextFiltersStates as ContextFilterState[])
  //console.log('Cached filter state in coalesce with fallback is: ', _.cloneDeep(cachedFilterState))
  if (isEmptyFilterComponentsState(cachedFilterState?.privileges)) {
    console.warn(
      'Warning: Attempt to coalesce filter states with no established privileges, returning original state.',
      _.cloneDeep(updatedFilterBarState)
    )
    return updatedFilterBarState
  } else {
    const currentPrivileges = cachedFilterState?.privileges
    const currentDisplayConfig = cachedFilterState?.display
    if (currentPrivileges && currentDisplayConfig) {
      const privilegedFilterState = applyNewFiltersState(currentPrivileges, currentDisplayConfig, filtersState)
      //console.log('Privileged filter state is: ', _.cloneDeep(privilegedFilterState))
      //console.log('Default components state is: ', _.cloneDeep(defaultFilterComponentsState))
      privilegedFilterState.context = context
      privilegedFilterState.selections = coalesceEmptyFilterComponentsAFromB(privilegedFilterState.selections, defaultFilterComponentsState)
      //console.log('Privileged filter state after coalesce atob is: ', _.cloneDeep(privilegedFilterState))

      updatedFilterBarState = setCachedFilterState(updatedFilterBarState, context, privilegedFilterState)
      //console.log('Updated filter state after setting cached filter state in coalesceBlindFilterState is: ', _.cloneDeep(updatedFilterBarState))

      updatedFilterBarState = setCurrentFilterState(updatedFilterBarState, privilegedFilterState)
      //console.log('Updated filter state in coalesceBlindFilterState is: ', updatedFilterBarState)
    } else {
      console.warn('Warning: Privileges not set prior to coalesce operation. ', _.cloneDeep(currentState))
      return { ...currentState }
    }
  }
  return { ...updatedFilterBarState }
}
const coalesceWithCachedStates = (pendingState: any, cachedState: any) => {
  //console.log('In coalesce with cahced states, pendingState, cachedState is: ', pendingState, cachedState)
  let { pendingFiltersState, pendingContext, defaultPendingFilterComponentsState } = pendingState
  const { cachedFiltersState, cachedContext, cachedDefaultState } = cachedState
  const cachedSelections = cachedFiltersState.selections
  const cachedDisplayConfig = cachedFiltersState.display

  const dateRangeStartIsDefault =
    cachedSelections?.dateRange?.start &&
    cachedSelections?.dateRange?.start !== null &&
    cachedDefaultState?.dateRange?.start &&
    cachedDefaultState?.dateRange?.start !== null
      ? filterMomentToString(cachedSelections?.dateRange?.start) === filterMomentToString(cachedDefaultState.dateRange.start)
      : false

  const dateRangeEndIsDefault =
    cachedSelections?.dateRange?.end &&
    cachedSelections?.dateRange?.end !== null &&
    cachedDefaultState?.dateRange?.end &&
    cachedDefaultState?.dateRange?.end !== null
      ? filterMomentToString(cachedSelections?.dateRange?.end) === filterMomentToString(cachedDefaultState.dateRange.end)
      : false

  //Set variables needed to evaluate pending state
  const pendingDisplayConfig = pendingFiltersState.display
  //Next we cycle through the pending state filter components to check for overlapping filter objects
  //Overlapping filter objects with non-empty entries will reset any selections carried over
  //from previous activity.
  const overlappingFilterObjects = Object.entries(cachedSelections).filter(([key, value]: any) => {
    //Use pending config as filter index since the display and filter components have the exact same keys, and display indicates whether or not that filter component is even used in this context.
    //Also need to check that the selection component is not empty. Null for date range, length = 0 for everything else
    return (
      pendingDisplayConfig[key] &&
      (key === FilterBarComponent.dateRange
        ? !(dateRangeStartIsDefault && dateRangeEndIsDefault)
        : (value && value.length > 0) || pendingFiltersState.selections[key].length > 0
        ? true
        : false)
    )
  })
  //console.log('Overlapping filter objects are: ', _.cloneDeep(overlappingFilterObjects))
  //Next find the filter components that are used in the new context but not in the old, if any
  const outerFilterObjects = Object.entries(pendingDisplayConfig).filter(([key, value]: any) => {
    return value === true && cachedDisplayConfig[key] === false
  })
  //console.log('Outer filter objects are: ', _.cloneDeep(outerFilterObjects))
  //Now coalesce the filter selections
  //Clone pending selections for use if coalesced overlapping filters results in a null filter state when it would not have been if valid values were used.
  const fallbackPendingSelections = _.cloneDeep({ ...pendingFiltersState.selections })
  if (overlappingFilterObjects?.length > 0) {
    //If there are overlapping filters, insert them into the default filter state of the pending state
    //This ensures the selections are clear
    pendingFiltersState = { ...pendingFiltersState, selections: { ...defaultPendingFilterComponentsState } }
    overlappingFilterObjects.forEach((filterEntry: any) => {
      const entryKey = filterEntry[0]
      const entryValue = filterEntry[1]
      pendingFiltersState = { ...pendingFiltersState, selections: { ...pendingFiltersState.selections, [entryKey]: entryValue } }
      //console.log('Pending filters state in overlap update is now: ', _.cloneDeep(pendingFiltersState))
    })
  }
  if (outerFilterObjects?.length > 0) {
    outerFilterObjects.forEach((filterEntry: any) => {
      const entryKey = filterEntry[0]
      pendingFiltersState = {
        ...pendingFiltersState,
        selections: { ...pendingFiltersState.selections, [entryKey]: _.cloneDeep(fallbackPendingSelections[entryKey]) },
      }
      //console.log('Pending filters state in outside filters update is now: ', _.cloneDeep(pendingFiltersState))
    })
  }
  if (!(overlappingFilterObjects?.length > 0) && !(outerFilterObjects?.length > 0)) {
    //If there is no overlap or outside cases, just set it back to what it was
    pendingFiltersState = { ...pendingFiltersState, selections: { ...fallbackPendingSelections } }
    //console.log('No outside or over in Pending filters state with cached state. Pending filter state set back to: ', _.cloneDeep(fallbackPendingSelections))
  }
  //console.log('Pending filter state to return is: ')
  return pendingFiltersState
}

const coalesceContextChangeFilterStates = (
  filterBarState: FiltersBarState,
  pendingContext: FilteredDashboards,
  pendingFiltersState: ContextFilterState,
  defaultPendingFilterComponentsState: FilterBarComponentsState
) => {
  let updatedFilterBarState = { ...filterBarState }
  //console.log('=-=-=-=-=-Initially, updated filterbar state in context change is: ', _.cloneDeep(updatedFilterBarState))
  //Set variables needed to evaluate current state
  const currentFiltersState = _.cloneDeep(updatedFilterBarState.filtersState)

  const currentContext = updatedFilterBarState.filtersState.context as FilteredDashboards
  //Before coalescing, we backup the current filters state to the cache
  updatedFilterBarState = setCachedFilterState(updatedFilterBarState, currentContext, currentFiltersState) as FiltersBarState
  //Make sure context is set
  pendingFiltersState = { ...pendingFiltersState, context: pendingContext }
  //Update pending filter state using all cached states
  const allCachedStates =
    updatedFilterBarState && updatedFilterBarState.contextFiltersStates ? updatedFilterBarState.contextFiltersStates : []
  const cachedFilterStates = allCachedStates.filter((cachedFiltersState: ContextFilterState) => {
    return cachedFiltersState && cachedFiltersState.context ? cachedFiltersState.context !== pendingContext : false
  })

  cachedFilterStates.forEach((cachedFiltersState: ContextFilterState) => {
    const pendingState = { pendingFiltersState, pendingContext, defaultPendingFilterComponentsState }
    const cachedContext = cachedFiltersState.context
    const cachedDefaultState = updatedFilterBarState.defaultComponentStates
      ? { ...updatedFilterBarState.defaultComponentStates[cachedContext as any] }
      : { ...emptyFilterComponentsState }
    //console.log('*******@@@@@@@@@@Current default state is: ', _.cloneDeep(cachedDefaultState))

    const cachedState = { cachedFiltersState, cachedContext, cachedDefaultState }
    const updatedPendingState = coalesceWithCachedStates(pendingState, cachedState)
    //console.log('Updated pending state in cache coalesce is: ', _.cloneDeep(updatedPendingState))
    pendingFiltersState = { ...pendingFiltersState, ...updatedPendingState }
  })

  //Reconcile whatever resulting selections there are with what is allowed
  pendingFiltersState = reduceFiltersStateFromPrivileges(pendingFiltersState)
  //console.log('5555555-----66666 coalesced pending filter state to be returned is: ', _.cloneDeep(pendingFiltersState))

  //Update the filter bar state cache
  updatedFilterBarState = setCachedFilterState(updatedFilterBarState, pendingContext, pendingFiltersState)
  //console.log('Updated filter bar state after setting cached filter state to include the pending filter state is: ', _.cloneDeep(updatedFilterBarState))

  //Set the pending filter state to the current state within the filters Bar
  updatedFilterBarState = setCurrentFilterState(updatedFilterBarState, pendingFiltersState)
  //console.log('Updated filter state after setting the pending state to the current state within the filters bar is: ', _.cloneDeep(updatedFilterBarState))

  //Return the coalesced filter bar state
  return { ...updatedFilterBarState }
}

export const reconcileFilterBarState = (state: FiltersBarState, pageContext: FilteredDashboards) => {
  if (state && state.filtersState && state.filtersState.context !== null) {
    const currentState = _.cloneDeep({ ...state.filtersState })
    const cachedStates = _.cloneDeep([...(state.contextFiltersStates as ContextFilterState[])]) //
    const cachedFilterState = getCachedFilterState(pageContext, cachedStates)
    let updatedFiltersBarState: FiltersBarState = _.cloneDeep({ ...state })
    const currentPrivileges = currentState.privileges
    const currentDisplayConfig = currentState.display

    const privilegedFilterState = applyNewFiltersState(currentPrivileges, currentDisplayConfig, currentState)

    updatedFiltersBarState = setCachedFilterState(updatedFiltersBarState, pageContext, privilegedFilterState)
    //console.log('Updated filter state after apply new selections in reconcileFilterBarState: ', _.cloneDeep(updatedFiltersBarState))

    updatedFiltersBarState = setCurrentFilterState(updatedFiltersBarState, privilegedFilterState)
    return { ...updatedFiltersBarState }
  }
  return { ...state }
}
export const coalesceFilterBarState = async (state: FiltersBarState, filterObjects: any): Promise<FiltersBarState> => {
  const { pageFilterContext: pageContext, parsedQueryParams, getFiltersForPage, defaultFilterState } = filterObjects
  const currentState = _.cloneDeep({ ...state.filtersState })
  const cachedStates = _.cloneDeep([...(state.contextFiltersStates as ContextFilterState[])]) //
  //console.log('Cached states are: ', cachedStates)
  const cachedFilterState = getCachedFilterState(pageContext, cachedStates)
  let updatedFiltersBarState: FiltersBarState = _.cloneDeep({ ...state })
  //console.log('----------------Payload to reconcile is: ', filterObjects)
  //console.log('Initially found cached filter state for context is: ', _.cloneDeep(cachedFilterState))
  //First store the default filter components values
  if (
    updatedFiltersBarState.defaultComponentStates &&
    isEmptyFilterComponentsState(updatedFiltersBarState.defaultComponentStates[pageContext] as FilterBarComponentsState)
  ) {
    updatedFiltersBarState = {
      ...updatedFiltersBarState,
      defaultComponentStates: {
        ...updatedFiltersBarState.defaultComponentStates,
        [pageContext]: { ...(defaultFilterState as FilterBarComponentsState) },
      },
    }
  }

  //console.log('After updating filter bar state to include defaults, state is: ', _.cloneDeep(updatedFiltersBarState))
  //First step is to check that the privileges exist for the current page context.
  if (isEmptyFilterComponentsState(cachedFilterState?.privileges)) {
    const responseObj = await getFiltersForPage()
    //console.log('Fetched data is: ', responseObj.data)
    // console.log('Filters Fetched data is: ', responseObj.data)
    const rawResponse = { ...responseObj.data }
    const responseData = updateDisplayNames(rawResponse)
    updatedFiltersBarState = setContextFilterFromResponse(updatedFiltersBarState, pageContext, responseData)
  }

  //---At this point, we can gaurantee that the privileges have been set for the page context/filter cache
  //Since we don't know whether or not the cached state had to be fetched or not, we have to re-extract the
  //set of cached states that may or may not have just been updated.
  const updatedCachedStates = _.cloneDeep([...(updatedFiltersBarState.contextFiltersStates as ContextFilterState[])])
  const updatedCachedFilterState = getCachedFilterState(pageContext, updatedCachedStates)

  //Because the current state may (from query filters) or may not (from alternate dashboard) have a null context,
  //we check the query filter state using the page context privileges
  //First check to see if we got here via URL
  const queryFilterComponentsState = getFiltersOjectFromURL(parsedQueryParams, updatedFiltersBarState.filtersState.privileges)
  //Check if the query state is empty or not. (Filters from URL)
  if (!isEmptyFilterComponentsState(queryFilterComponentsState)) {
    //If there are query params, then coalesce with the default state and return
    const queryFilterState: ContextFilterState = {
      ...updatedFiltersBarState.filtersState,
      context: pageContext,
      selections: queryFilterComponentsState,
    }
    // Options for query state set within the next function
    updatedFiltersBarState = coalesceBlindFilterState(updatedFiltersBarState, pageContext, queryFilterState, defaultFilterState)
    //Since this corresponds to the first time a filter context is being set, we can return
    return updatedFiltersBarState
    //check privelages
    //set context
    //set to current
  } //If no query params, check to see if there is a current filter state
  else if (currentState.context === null) {
    //Neccessarily means that query filters were empty
    //null filter state means this is the first time a filter is being set or we got here from a query filter
    //If there isnt a current filter state (no context, no values that are not null, meaning not even default)
    //Then:
    //Set filterstate to coalesced version of default state and cached/just fetched filter state for page
    updatedFiltersBarState = coalesceBlindFilterState(updatedFiltersBarState, pageContext, emptyContextFilterState, defaultFilterState)
    // updatedFiltersBarState = setCurrentFilterStateFromCache(updatedFiltersBarState, pageContext)
    //Since this corresponds to the first time a filter context is being set, we can return
    return updatedFiltersBarState
  }
  //If the current state isn't totally empty we want to capture as much of it as possible
  else if (currentState.context !== null) {
    //context isn't null, that means we need to check it agains the page context
    //If the context is not null, it means we've navigated here from another dashboard
    if (currentState.context === pageContext) {
      //If the current context matches the page context, we don't really need to do anything,
      //the currentState is the most up to date state.
      //check privileges
      updatedFiltersBarState = reconcileFilterBarState(updatedFiltersBarState, pageContext)
    } else {
      //If contexts don't match, check to see if there is a cached version for the page
      //Make sure there are cached states
      //Check to see if there are any non-null fields within the selections that can be updated in the cached version
      //If there is any overlap, we'll clone the cached version and then modify the cached version to match the current filter state
      //Fields not included in the current filter are reset to empty or default if date dateRange
      //Lastly, we review the clone and see if there are non empty fields in it that are now empty in the newly cached version
      //If we find something that exists within the new set of cached options, it gets copied over
      //Otherwise just return the newly cached version
      //If no cached version is found, use the default value for the page (passed as an arg here)
      //Then modify the default state to include data from overlapping fields where privileges match
      // updatedFiltersBarState = mergeCurrentStateToPageDefault(currentState, defaultFilterState, responseData)
      //Copy new state to cachedState for page
      //New state becomes the current state for the filters bar
      //Fetched data gauranteed to exist in context states
      //Current state is the state we are changing from
      //Pending state is the state we are changing to
      const pendingFilterState: any = { ...updatedCachedFilterState }

      //We have to make sure the filter has at least the default values
      if (isEmptyFilterComponentsState(pendingFilterState?.selections)) {
        pendingFilterState.selections = { ...defaultFilterState }
      }
      updatedFiltersBarState = coalesceContextChangeFilterStates(
        updatedFiltersBarState,
        pageContext,
        pendingFilterState as ContextFilterState,
        defaultFilterState
      )
    }
  }
  return updatedFiltersBarState
}
