import React from 'react'
import { useFela } from 'react-fela'
import Select from 'react-select'
import { debugBorder } from '../../styles/containers'
import { styles } from '../../../styles/scss/styling'
import { baseText } from '../../styles/text'
import { formControlBackground, grayInput, pathspotPrimary, pathspotWhite } from '../../../styles/ts/colors'
import { btnFocusBoxShadow, btnFormBoxShadow } from '../../styles/buttons'
export const dropdown = (args: any) => {
  const { flexDirection, width, boxShadow, height, maxHeight } = args
  return {
    flexDirection: 'row',
    minWidth: width || '100%',
    boxShadow: boxShadow ? (boxShadow === 'none' ? undefined : boxShadow) : btnFormBoxShadow,
    height: height || 'auto',
    maxHeight,
  }
}

const dropdownSelector = (args: any) => {
  const {
    borderRadius,
    borderLeft,
    borderTop,
    borderRight,
    borderBottom,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
  } = args
  return {
    borderRadius: `${borderRadius ? borderRadius : '0'} !important`,
    borderBottomRightRadius: borderRight ? (borderBottomRightRadius ? borderBottomRightRadius : borderRadius) : '0.25rem',
    borderTopRightRadius: borderRight ? (borderTopRightRadius ? borderTopRightRadius : borderRadius) : '0.25rem',
    borderBottomLeftRadius: `${borderLeft ? (borderBottomLeftRadius ? borderBottomLeftRadius : borderRadius) : '0rem'} !important`,
    borderTopLeftRadius: `${borderLeft ? (borderTopLeftRadius ? borderTopLeftRadius : borderRadius) : '0rem'} !important`,
  }
}

const LabelText = (props: any) => {
  const { className, label, value } = props

  return <span className={className}>{`${label}`}</span>
}

const PDropdownSelect = (props: any) => {
  const {
    value,
    options,
    onChange,
    isMulti,
    placeholder,
    name,
    width,
    height,
    closeOnSelect,
    fontSize,
    fontWeight,
    label,
    color,
    boxShadow,
    backgroundColor,
    borderLeft,
    borderRight,
  } = props
  // console.log('Width in PDropdownSelect is: ', width)
  const { css } = useFela()
  return (
    <div
      className={css(
        dropdown({
          width: width,
          height: height,
          maxHeight: height,
          boxShadow: boxShadow,
        })
      )}
    >
      <Select
        name={name || 'Select'}
        closeMenuOnSelect={closeOnSelect || true}
        className={css(dropdownSelector({ borderLeft, borderRight }))}
        placeholder={placeholder || 'Please select an item from the list.'}
        value={value ? value : undefined}
        styles={{
          placeholder: (base: any) => ({
            ...base,
            fontSize: fontSize || '1rem',
            color: color || grayInput,
            backgroundColor: backgroundColor || formControlBackground,
            fontWeight: fontWeight || 400,
          }),
        }}
        // value={}
        formatOptionLabel={(styles: any) => {
          // console.log('Option is: ', styles)
          return (
            <LabelText {...styles} className={css(baseText({ color: color || grayInput, fontSize: fontSize, fontWeight: 'normal' }))} />
          )
        }}
        options={options}
        isMulti={isMulti}
        isDisabled={false}
        onChange={(value: any) => {
          // console.log('Value in dropdown select is: ', value)
          if (onChange) onChange(value)
        }}
      />
    </div>
  )
}
export default PDropdownSelect
